<template>
  <div class="main-wrap selfBrandList">
    <div style="display: none">赢商大数据云商管系统</div>
    <div class="page-main">
      <img
        v-if="hideE"
        class="entrance-img"
        src="@/assets/images/entrance.png"
        @click="toEntrance()"
      />
      <!-- 搜索条件 -->
      <search ref="matchTypeFunc" @matchTypeFunc="matchTypeFunc" :orderType.sync="orderType"></search>
      <!-- 搜索结果 -->
      <list :matchType="matchType" :orderType="orderType" :menuFilterId="menuFilterId"></list>
      <!-- 接口访问失败 -->
      <data-error v-if="loadingError"></data-error>
      <!-- <div class="first-user-mask" v-show="showFirstMask"> -->
      <!-- <div class="first-user-main" style="margin-top: 140px;"> -->
      <!-- <p>这里我们为您提供全面的品牌数据库以及多维度的筛选，方便您寻找想要了解的品牌</p> -->
      <!-- <span class="first-user-btn" @click="hideFirstMask">我知道了</span> -->
      <!-- <img src="../../assets/images/update2.0.png" @click="hideMask()"> -->
      <!-- </div> -->
      <!-- </div> -->
      <brand-list-bottom></brand-list-bottom>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Search from './Search'
import List from './List'
import DataError from '@/components/DataError.vue'
import brandListBottom from '../brandTendency/brandListBottom'
// import { setCookie, getCookie } from '../../utils'
export default {
  name: 'SelfBrandList',
  components: {
    Search: Search,
    List: List,
    DataError: DataError,
    brandListBottom
  },
  computed: {
    ...mapGetters({
      loadingError: 'loadingError',
      user: 'user'
    })
  },
  data () {
    return {
      orderType: 9001,
      showFirstMask: false,
      matchType: 0,
      menuFilterId: 9100,
      hideE: false,
      interestsData: {}
    }
  },
  created () {
    // this.isFristLogin()
  },
  mounted () {
    this.interestsData = JSON.parse(localStorage.getItem('wisdom_interests'))
    const hasPermission = this.user.confIds.filter(item => {
      return item.id === 86
    })
    if (this.user.isadmin === 0) {
      this.hideE = true
    } else if (this.interestsData.totalmatchnum > 0) {
      this.hideE = true
    } else if (hasPermission.length) {
      this.hideE = true
    } else {
      this.hideE = false
    }
  },
  methods: {
    matchTypeFunc (item) {
      this.matchType = item
    },
    hideMask () {
      this.showFirstMask = false
    },
    toEntrance () {
      const mockHref = this.$router.resolve({
        path: '/intelBrandList'
      })
      window.open('/' + mockHref.href, '_blank')
    }
  }
  // mounted () {
  // this.$fullLoading.close()
  // 第一次登录时，未刷新，显示操作提示
  // if (this.user.isFristLogin === 1) {
  //   if (getCookie('selfBrandList') === '1') {
  //     this.showFirstMask = false
  //   } else {
  //     let firstLoginCookieTmp = {
  //       key: 'firstLogin',
  //       value: '1',
  //       expireDays: 0
  //     }
  //     setCookie(firstLoginCookieTmp)
  //     this.showFirstMask = true
  //     this.$nextTick(() => {
  //       document.body.style.overflowY = 'hidden'
  //     })
  //   }
  // } else {
  //   if (getCookie('firstLogin') === '1') {
  //     if (getCookie('selfBrandList') === '1') {
  //       this.showFirstMask = false
  //     } else {
  //       // 未关闭弹窗，显示操作提示
  //       let cookieTmp = {
  //         key: 'selfBrandList',
  //         value: '0',
  //         expireDays: 0
  //       }
  //       setCookie(cookieTmp)
  //       this.showFirstMask = true
  //       this.$nextTick(() => {
  //         document.body.style.overflowY = 'hidden'
  //       })
  //     }
  //   } else {
  //     // 不是第一次登录，且重新打开浏览器
  //     this.showFirstMask = false
  //   }
  // }
  // }
  // methods: {
  //   hideFirstMask () {
  //     let cookieTmp = {
  //       key: 'selfBrandList',
  //       value: '1',
  //       expireDays: 0
  //     }
  //     setCookie(cookieTmp)
  //     this.showFirstMask = false
  //     this.$nextTick(() => {
  //       document.body.style.overflowY = 'scroll'
  //     })
  //   }
  // }
}
</script>

<style lang="stylus" scoped>
.first-user-main
  cursor pointer
.entrance-img
  position fixed
  right 0
  top 180px
  width 196px
  height 157px
  cursor pointer
  z-index 111
</style>
