<template>
  <div class="brand-tab">
    <div class="brand-tab-item">
      <span
        :class="[type==='brandTendency'?'active':'']"
        @click="selectLabel('brandTendency')"
      >商业洞察</span>
      <span
        :class="[type==='brandList'?'active':'']"
        @click="selectLabel('brandList')"
      >品牌盘点</span>
      <span @click="selectLabel('brandTop')">品牌榜单
        <i class="new-label"></i></span>
      <i
        class="see-more"
        @click="toBrandTendency"
      >
        更多>>
      </i>
    </div>
    <ul class="brand-tab-list">
      <li
        v-for="item in newsList"
        :key="item.newsId"
        @click="toNewsDetail(item)"
      >
        <div class="brand-logo">
          <img
            v-if="!item.firstPic"
            src="@/assets/images/public/notPic.jpg"
            alt=""
          >
          <img
            v-else
            :src="item.firstPic"
          />
        </div>
        <div class="brand-info">
          <div class="brand-info-title">
            <h3>{{item.title}}</h3>
          </div>
          <p>{{item.hexintishi}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      type: 'brandTendency',
      pageNum: 1,
      pageSize: 5,
      newsList: []
    }
  },
  components: {
  },
  created () {
    this.getList()
  },
  methods: {
    selectLabel (type) {
      if (type === 'brandTop') {
        this.toBrandTopList()
      } else {
        this.type = type
        this.getList()
      }
    },
    getList () {
      this.loading = true
      if (this.type === 'brandTendency') {
        this.axios.post(api.getNewsBrandNewsSearch, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          category: '推荐',
          keyWord: ''
        })
          .then((res) => {
            this.loading = false
            if (res.data.code === 0) {
              this.newsList = res.data.data.newsListVos.slice(0, 5)
            }
          })
      } else {
        this.getInventoryList()
      }
    },
    getInventoryList () {
      this.axios.post(api.getNewsBrandCheck, {
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.newsList = res.data.data.slice(0, 5)
          }
        })
    },
    toNewsDetail (detail) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: this.type === 'brandTendency' ? encodeURIComponent('商业洞察列表') : encodeURIComponent('品牌盘点'),
          newsId: detail.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    toBrandTendency (detail) {
      const page = this.$router.resolve({
        path: '/brandTendency'
      })
      window.open('/' + page.href, '_blank')
    },
    toBrandTopList (detail) {
      const page = this.$router.resolve({
        path: '/brandTendencyTop',
        query: {
        }
      })
      window.open('/' + page.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-tab
  background-color #272930
  margin-top 16px
.brand-tab-item
  display flex
  padding 0 35px
  position relative
  span
    display inline-block
    cursor pointer
    line-height 30px
    margin-right 64px
    margin-top 20px
    text-align center
    font-weight 500
    font-size 18px
    color #fff
    position relative
    margin-right value
    border-bottom 2px solid transparent
    transition all 0.4s
    &.active
      border-bottom 2px solid #FFA134
    &:hover
      border-bottom 2px solid #FFA134
.brand-tab-list
  display flex
  padding 36px
  li
    width 220px
    box-shadow 0px 5px 8px 0px rgba(30, 30, 35, 0.8)
    transition all 0.3s
    margin-right 27px
    &:last-child
      margin-right 0
    &:hover
      box-shadow 0px 5px 10px 2px #000000
.brand-logo
  width 100%
  height 145px
  overflow hidden
  cursor pointer
  position relative
  img
    width 100%
.brand-info
  padding 12px
  h3
    font-size 16px
    color #ffffff
    line-height 22px
    display -webkit-box
    -webkit-box-orient vertical
    -webkit-line-clamp 2
    overflow hidden
    margin-bottom 14px
    cursor pointer
  p
    color #999
    font-size 12px
    line-height 17px
    cursor pointer
    display -webkit-box
    -webkit-box-orient vertical
    -webkit-line-clamp 3
    overflow hidden
.brand-info-title
  display flex
  align-items center
  height 45px
.see-more
  font-size 14px
  text-align center
  color rgba(255, 255, 255, 0.5)
  cursor pointer
  position absolute
  top 25px
  right 35px
.new-label
  display inline-block
  position absolute
  right -12px
  top 0px
  width 12px
  height 12px
  background url('../../assets/images/newLabel.png') no-repeat
  background-size 100%
  z-index 111
</style>
