<template>
  <div
    class="list-wrap"
    v-show="!loadingError"
    v-loading.fullscreen="selfSearch.loading"
    element-loading-text="拼命加载中"
  >
    <ul class="clearfix">
      <brand-list-item
        :detail="item"
        :matchType="matchType"
        :orderType="orderType"
        :showBtn='false'
        type="selfBusiness"
        v-for="(item, index) in selfList.dataList"
        :key="index"
        @showMatch="showMatch"
      >
      </brand-list-item>
    </ul>
    <div
      class="no-data"
      v-if="selfList.dataList && selfList.dataList.length === 0"
    >
      <p class="no-list-data"></p>
      <span v-if="orderType !== 9005 && menuFilterId!==9102">很抱歉，暂时无法找到符合条件的品牌，您可以更新条件重新搜索</span>
      <p
        v-if="orderType === 9005 || menuFilterId ===9102"
        style="font-size: 22px; color: #c2c2c2;"
      >当前暂无关注的品牌</p>
      <p
        v-if="orderType === 9005 || menuFilterId ===9102"
        style="font-size: 18px; color: #c2c2c2; margin-top: 16px;"
      >您可在品牌详情页点击“关注”加入关注列表，获取品牌最新更新动态及提醒</p>
    </div>
    <div class="w-page-sty">
      <el-pagination
        background
        :layout="layout"
        :class="{'morepage': selfList.pages > 500}"
        v-if="selfList.dataList && selfList.dataList.length > 0"
        :current-page="selfSearch.pageNum"
        @current-change="handleCurrentChange"
        :page-size="selfSearch.pageSize"
        :total="selfList.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import { mapGetters } from 'vuex'
import BrandListItem from './BrandListItem'
import { checkParams } from '../../utils'
import Bus from '../../utils/bus.js'
export default {
  name: 'selfBrandList',
  components: {
    BrandListItem: BrandListItem
  },
  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user',
      selfList: 'selfList',
      loadingError: 'loadingError',
      selfSearch: 'selfSearch'
    })
  },
  // watch: {
  //   selfList: function (newQuestion) {
  //     this.downBrandNumber = 0
  //     this.downChecked = true
  //     for (const item of newQuestion.dataList) {
  //       if (!item.check) {
  //         this.downChecked = false
  //       } else {
  //         this.downBrandNumber++
  //       }
  //     }
  //     if (newQuestion.dataList.length === 0) {
  //       this.downChecked = false
  //     }
  //   }
  // },
  props: {
    orderType: {
      type: Number
    },
    matchType: {
      type: Number
    },
    menuFilterId: {
      type: Number
    }
  },
  created () {
    // 试用用户
    // if (this.user.type === '10') {
    //   // this.layout = 'prev, next'
    // } else {
    //   this.layout = 'prev, pager, next'
    // }
    this.layout = 'prev, pager, next'
    const params = {
      pageNum: 1,
      brand_name: '',
      project_id: this.user.projectId,
      loading: true
    }
    this.$store.dispatch('setSelfSearch', params)
    // this.initData()
  },
  // activated () {
  //   this.initData()
  // },
  data () {
    return {
      layout: 'pager'
    }
  },
  mounted () {
    // setTimeout(() => {
    //   this.hasDownExcelInterests()
    // }, 400)
    Bus.$on('isHasShowExportList', (item) => {
      this.isHasDownExcel = item
    })
  },
  methods: {
    hasDownExcelInterests () {
      this.axios.post(api.getDownExcelIsShow)
        .then((res) => {
          this.isHasDownExcel = res.data.data
        })
    },
    showMatch () {
      this.$emit('showMatchIndex', true)
    },
    handleCurrentChange (val) {
      const params = {
        pageNum: val,
        loading: true
      }
      this.$store.dispatch('setSelfSearch', params)
      this.initData()
    },
    initData () {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      })
      // 品牌列表
      this.axios.post(api.selfBrandList, checkParams(this.selfSearch, this.orderType))
        .then((res) => {
          console.log('res', res)
          if (res.data.code === 0) {
            console.log('res.data.data', res.data.data)
            this.$store.dispatch('setSelfBrandList', res.data.data)
          } else {
            // this.$message.error(res.data.message)
          }
          const params = {
            loading: false
          }
          this.$store.dispatch('setSelfSearch', params)
        })
        .catch(() => {
          const params = {
            loading: false
          }
          this.$store.dispatch('setSelfSearch', params)
        })
    }
  }
}
</script>

<style lang="stylus">
.list-wrap
  min-height 700px
  padding-top 6px
.el-loading-spinner
  top 40%
</style>
