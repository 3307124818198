<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="导出品牌中..."
  >
    <!-- <div class="msg-notice" v-if="massageList.length > 0">
      <div class="msg-left">
        <i class="icon-msg"></i>
        <span>数据更新通知：</span>
      </div>
      <div class="msg-right">
        <ul :style="{marginLeft: '-' + marginLeft + 'px' }">
        <ul>
          <li
            v-for="(item, index) in massageList"
            :key="index">{{item.mtext}} <span @click="toMessage">【查看明细】</span> </li>
        </ul>
      </div>
    </div> -->
    <div
      class="rand-search clearfix"
      :class="{'no-keyworld': brandKeyWord === '请输入品牌名称'}"
    >
      <input
        type="text"
        v-model="brandKeyWord"
        @keyup.enter="fetchListData(true)"
        @keyup.down="changeData('down')"
        @keyup.up="changeData('up')"
        placeholder="请输入品牌名称"
        @input="keyWordInput"
        :style="{'width': hasLibrary ? '985px' : '1170px'}"
      />
      <span @click="fetchListData(true)">搜 索</span>
      <i
        v-if="hasLibrary"
        @click="addBrandClick"
        class="add-brand-btn"
      ><i class=""></i>企业品牌库</i>
      <transition name="fade">
        <div
          class="select-list d_jump"
          id="searchBar"
          v-show="brandKeyWordListShow"
        >
          <ul>
            <li
              v-show="!brandLoad"
              @click.stop="selectBrand(items)"
              :class="{'select-list-active': currentSearhItem === index}"
              :key="index"
              v-for="(items, index) in brandKeyWordList"
            >
              {{items.brandName}}
            </li>
            <li v-show="brandKeyWordList.length === 0 && !brandLoad">无相关品牌数据！</li>
          </ul>
        </div>
      </transition>
    </div>
    <div class="condition-wrap">
      <!-- 品牌属性 -->
      <div
        class="condition-tit clearfix"
        @click="showBrandAttrHandler"
      >
        <div class="fl condition-left-text">品牌属性</div>
        <div class="fr condition-right-text">
          {{showBrandAttr ? '收起' : '展开'}}全部品牌属性
          <span
            class="icon-arrow-down"
            :class="{'arrow-active': showBrandAttr}"
          ></span>
        </div>
      </div>
      <div
        class="condition-content"
        :class="[showBrandAttr ? '' : 'contentBorder']"
      >
        <search-item
          title="业态类别"
          :noSelect.sync="noSelect"
          :canSelectAll="false"
          :noNeedbottomBorder="true"
          :list.sync="businessType"
          :noCondition="businessTypeNoCondition"
          :needCurrentId="true"
          :currentId.sync="currentId"
          :hasSelect="currentBusinessType"
          @handleItem="handleItem"
          type="business"
        >
        </search-item>
        <transition name="fade">
          <div v-if="!noSelect">
            <div
              class="condition-item-detail"
              style="background-color: #444b57;font-size: 14px"
              v-show="businessDetailType && businessDetailType.length > 0"
            >
              <div class="condition-b-l"></div>
              <span
                class="condition-item"
                :class="{'condition-active': noBusinessCondition}"
                @click="selectNoBusinessCondition()"
              >不限</span>
              <span
                class="condition-item"
                :key="key"
                v-for="(item, key) in businessDetailType"
                @click="selectItem(item, key)"
                :class="{'condition-active': item.id === currentChildId}"
              >
                {{item.item}}
              </span>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="businessDetailThreeType.length > 0"
            class="condition-detail-wrap"
          >
            <div
              class="condition-item-detail clearfix"
              v-show="!noSelect"
            >
              <div class="condition-b-l"></div>
              <!-- <span
                class="fl condition-item"
                style="color: #fff;margin-right: 5px">TOP20热门业态标签</span> -->
              <el-checkbox-group
                class="fl"
                style="margin-top: 7px; width: 980px"
                v-model="businessDetailThree"
                @change="selectBusinessTagMoreSure"
              >
                <el-checkbox
                  :label="item.id"
                  :key="key"
                  style="margin-left:15px;margin-bottom:10px"
                  v-for="(item, key) in businessDetailThreeType"
                >{{item.item}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </transition>
        <search-item
          title="人均消费/客单价"
          :selectMoreState="true"
          :selectShowMore="true"
          :list.sync="peoplePriceType"
          :noCondition="peoplePriceTypeNoCondition"
          v-if="peoplePriceType.length > 0"
          @handleItem="handleItem"
          type="peoplePrice"
        >
        </search-item>
        <search-item
          title="品牌档次"
          :selectMoreState="true"
          :selectShowMore="true"
          :list.sync="brandGradeType"
          :noCondition="brandGradeTypeNoCondition"
          v-if="brandGradeType.length > 0"
          @handleItem="handleItem"
          type="brandGrade"
        >
        </search-item>
        <!-- <search-item
          title="数据类型"
          :selectMoreState="true"
          :selectShowMore="true"
          :list.sync="dataType"
          :noCondition="dataTypeNoCondition"
          v-if="dataType.length > 0"
          @handleItem="handleItem"
          type="dataType"
        >
        </search-item> -->
        <transition name="fade">
          <div v-show="showBrandAttr">
            <search-item
              title="品牌级次"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="brandClassType"
              :noCondition="brandClassTypeNoCondition"
              v-if="brandClassType && brandClassType.length > 0"
              @handleItem="handleItem"
              type="brandClass"
            >
            </search-item>
            <search-item
              title="合作关系"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="cooperationshipType"
              :noCondition="cooperationshipTypeNoCondition"
              v-if="cooperationshipType.length > 0"
              @handleItem="handleItem"
              type="cooperationship"
            >
            </search-item>
            <!-- {{createCityId}} -->
            <search-item
              title="创立地"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="createCityType"
              :noCondition="createCityTypeNoCondition"
              v-if="createCityType"
              @handleItem="handleItem"
              type="createCity"
            >
            </search-item>
            <search-item
              title="开店方式"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="shopType"
              :noCondition="shopTypeNoCondition"
              v-if="shopType"
              @handleItem="handleItem"
              type="shop"
            >
            </search-item>
          </div>
        </transition>
      </div>
      <!-- 品牌标签 -->
      <div class="condition-tit clearfix">
        <div class="fl condition-left-text">品牌标签</div>
        <!-- <div class="fr condition-right-text" @click="open4" style="padding-right: 0">鼠标移上对应选项内容即可查看其说明注释 <span style="border-bottom: 1px solid #aeacb0; padding-bottom: 2px;">查看示例</span></div> -->
      </div>
      <div class="condition-content">
        <search-item
          title="品牌分类"
          :needShowTip="true"
          :selectMoreState="true"
          :selectShowMore="true"
          :noNeedbottomBorder="true"
          :list.sync="brandTagType"
          :noCondition="brandTagNoCondition"
          :disabledCityIds="checkOpenCityList"
          v-if="brandTagType"
          :noSelect.sync="noBrandTagSelect"
          @handleItem="handleItem"
          style="border-bottom: 1px solid rgba(226,226,226,0.1)"
          type="brandTag"
        >
        </search-item>
        <transition name="fade">
          <div
            v-if="showLocalBrandCity"
            class="condition-detail-wrap consition-long-checkbox"
          >
            <div
              class="condition-item-detail clearfix"
              v-show="!noBrandTagSelect"
            >
              <span class="conditon-sj"></span>
              <div
                class="condition-b-l"
                style="height: 103px;"
              ></div>
              <el-checkbox-group
                class="fl"
                v-model="checkLocalBrandCityList"
                @change="selectLocalBrandCityMoreSure"
              >
                <el-checkbox
                  :label="item.id"
                  :key="key"
                  v-for="(item, key) in localBrandCityType"
                >{{item.item}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </transition>
        <search-item
          title="品牌特色"
          :needShowTip="true"
          :selectMoreState="true"
          :selectShowMore="true"
          :list.sync="brandSpecialType"
          :noCondition="brandSpecialNoCondition"
          v-if="brandSpecialType"
          @handleItem="handleItem"
          type="brandSpecial"
        >
        </search-item>
        <!-- https://modao.cc/app/FV0i4BDYr9n20phJ0GFKpc#screen=sl2senl5jt22wyn需求添加 -->
        <!-- 本土品牌 -->
        <search-item
          title="本土品牌"
          :noSelect.sync="noSelectMainland"
          :canSelectAll="false"
          :noNeedbottomBorder="true"
          :list.sync="mainlandType"
          :noCondition="mainlandTypeNoCondition"
          :needCurrentId="true"
          :currentId.sync="currentMainlandId"
          :hasSelect="currentMainlandType"
          @handleItem="handleItem"
          type="mainland"
          v-if="createCityId.indexOf('9201') > -1 || !createCityId"
        >
        </search-item>
        <transition name="fade">
          <div v-if="!noSelectMainland">
            <div
              class="condition-item-detail"
              style="background-color: #444b57;font-size: 14px"
              v-show="mainlandDetailType && mainlandDetailType.length > 0"
            >
              <div class="condition-b-l"></div>
              <span
                class="condition-item"
                :class="{'condition-active': noMainlandCondition}"
                @click="selectNoMainlandCondition()"
              >不限</span>
              <span
                class="condition-item"
                :key="key"
                v-for="(item, key) in mainlandDetailType"
                @click="selectItemMainland(item, key)"
                :class="{'condition-active': item.id === currentMainlandChildId}"
              >
                {{item.item}}
              </span>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="mainlandDetailThreeType.length > 0"
            class="condition-detail-wrap"
          >
            <div
              class="condition-item-detail clearfix"
              v-show="!noSelectMainland"
            >
              <div class="condition-b-l"></div>
              <!-- <span
                class="fl condition-item"
                style="color: #fff;margin-right: 5px">TOP20热门业态标签</span> -->
              <el-checkbox-group
                class="fl"
                style="margin-top: 7px; width: 980px"
                v-model="mainlandDetailThree"
                @change="selectMainlandTagMoreSure"
              >
                <el-checkbox
                  :label="item.id"
                  :key="key"
                  style="margin-left:15px;margin-bottom:10px"
                  v-for="(item, key) in mainlandDetailThreeType"
                >{{item.item}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </transition>
        <search-item
          title="自定义标签"
          question="自定义标签为自主添加品牌标签，为品牌添加自定义标签可进入品牌编辑页-自定义标签进行添加"
          :selectMoreState="true"
          :selectShowMore="true"
          :list.sync="selfTagType"
          :noCondition="customLabelNoCondition"
          v-if="selfTagType.length>0"
          @handleItem="handleItem"
          type="selfTag"
        >
        </search-item>
      </div>
      <!-- 开店情况 -->
      <div
        class="condition-tit clearfix"
        @click="showOpenShopHandler"
      >
        <div class="fl condition-left-text">1-4线城市开店情况</div>
        <div class="fr condition-right-text">
          {{showOpenShop ? '收起' : '展开'}}开店情况
          <span
            class="icon-arrow-down"
            :class="{'arrow-active': showOpenShop}"
          ></span>
        </div>
      </div>
      <div class="condition-content borderNone">
        <!-- 省份开店城市 -->
        <div class="open-city-style">
          <div>
            <div class="condition-block condition-checkbox clearfix">
              <span class="fl condition-tit1">开店城市</span>
              <div class="fl condition-right">
                <span
                  class="condition-item"
                  style="margin-left: 14px;"
                  :class="{'condition-active': noOpenCheckedSel}"
                  @click="openCityUnlimited"
                >不限</span>
                <span
                  class="condition-item"
                  :class="[openCityIdx === item.id ? 'condition-active' : '']"
                  v-for="item in openLineType"
                  :key="item.id"
                  @click="openCityFunc(item)"
                >{{item.item}}</span>
              </div>
            </div>
          </div>
          <!-- 城市 -->
          <transition name="fade">
            <div class="condition-detail-wrap">
              <div
                class="clearfix condition-item-detail"
                v-show="!noOpenCheckedSel"
              >
                <el-checkbox-group
                  class="fl"
                  style="margin-top: 7px;margin-left: 7px;"
                  v-model="checkOpenCityList"
                  @change="selsectOpenCityDetails"
                >
                  <el-checkbox
                    v-for="item in openCityDetailList"
                    :key="item.id"
                    :label="item.id"
                    :disabled="disabledCityIds.includes(item.id)"
                  >{{item.item}}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </transition>
        </div>
        <transition name="fade">
          <div v-show="showOpenShop">
            <search-item
              title="开店数量"
              :needShowTip="true"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="projectNumberType"
              :noCondition="projectNumberNoCondition"
              v-if="projectNumberType"
              @handleItem="handleItem"
              type="projectNumber"
            >
            </search-item>
            <search-item
              title="楼层偏好"
              :needShowTip="true"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="openHobbyType"
              :noCondition="openHobbyNoCondition"
              v-if="openHobbyType"
              @handleItem="handleItem"
              type="openHobby"
            >
            </search-item>
          </div>
        </transition>
      </div>
      <!-- 拓展需求 -->
      <div
        class="condition-tit clearfix"
        @click="showExpandNeedHandler"
      >
        <div class="fl condition-left-text">拓展需求</div>
        <div class="fr condition-right-text">
          {{showExpandNeed ? '收起' : '展开'}}全部拓展需求
          <span
            class="icon-arrow-down"
            :class="{'arrow-active': showExpandNeed}"
          ></span>
        </div>
      </div>
      <div class="condition-content">
        <search-item
          title="需求面积"
          :selectMoreState="true"
          :selectShowMore="true"
          :list.sync="areaType"
          v-if="areaType"
          :noCondition="areaTypeNoCondition"
          @handleItem="handleItem"
          type="area"
        >
        </search-item>
        <transition name="fade">
          <div v-show="showExpandNeed">
            <search-item
              title="计划拓展城市"
              :noSelect.sync="noSelectCity"
              :canSelectAll="false"
              :noNeedbottomBorder="true"
              :list.sync="shopExpandPlanType"
              :needCurrentId="true"
              :noCondition="shopExpandPlanTypeNoCondition"
              :currentId.sync="currentCityId"
              @handleItem="handleItem"
              type="cityLine"
            >
            </search-item>
            <transition name="fade">
              <div class="condition-detail-wrap">
                <div
                  class="clearfix condition-item-detail"
                  v-show="!noSelectCity"
                >
                  <div class="condition-b-l"></div>
                  <span
                    class="fl condition-item cityspan"
                    :class="{'condition-active': noConditionCity}"
                    @click="selectNoConditionCity()"
                  >不限</span>
                  <el-checkbox-group
                    class="fl"
                    style="margin-top: 9px;margin-left: 55px;"
                    v-model="checkCityLineList"
                    @change="selectCityMoreSure"
                  >
                    <el-checkbox
                      :label="item.id"
                      :key="key"
                      v-for="(item, key) in cityLineDetailType"
                    >{{item.item}}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </transition>
            <search-item
              title="计划开店"
              :selectMoreState="true"
              :selectShowMore="true"
              :list.sync="shopNumType"
              v-if="shopNumType"
              :noCondition="shopNumTypeNoCondition"
              @handleItem="handleItem"
              type="shopNum"
            >
            </search-item>
          </div>
        </transition>
      </div>
    </div>
    <div
      id="srcollBox"
      class="has-select"
      :class="{'mock-back-fix': backBtnFix}"
    >
      <!-- 五个版本 -->
      <div
        class="five-version"
        :class="[backBtnFix ? 'mock-back-fix-wrap': '']"
      >
        <div class="order-wrap">
          <div class="w-input-item w-input-sty">
            <el-cascader
              v-model="currentOrderType"
              :options="orderOptions"
              size="small"
              style="width: 150px;"
              popper-class="black-select-panel"
              :props="{ expandTrigger: 'hover' }"
              @change="handleChange"
            ></el-cascader>
            <i
              class="el-icon-warning cursor"
              @click.stop="tipsShowFunc"
            > </i>
          </div>
          <template>
            <!-- <span
              @click.stop="selectSortFunc"
              class="five-version-text"
            >{{selectOrderItem}}
              <i
                class="el-icon-warning"
                @click.stop="tipsShowFunc"
              > </i>
              <i
                @click.stop="selectSortFunc"
                :class="[selectSort ? 'el-icon-arrow-up': 'el-icon-arrow-down']"
              ></i>
              <ul
                v-if="selectSort"
                :style="{top:(backBtnFix ? '50px': '48px')}"
                class="order-ul"
              >
                <li
                  :class="[selectOrderItem === '默认排序' ? 'active' : '']"
                  @click="selectSortClick(9001, '默认排序')"
                >默认排序</li>
                <li
                  :class="[selectOrderItem === '最新更新' ? 'active' : '']"
                  @click="selectSortClick(9002, '最新更新')"
                >最新更新</li>
                <li
                  :class="[selectOrderItem === '人气指数' ? 'active' : '']"
                  @click="selectSortClick(9006, '人气指数')"
                >人气指数</li>
                <li
                  :class="[selectOrderItem === '潜力指数' ? 'active' : '']"
                  @click="selectSortClick(9008, '潜力指数')"
                >潜力指数</li>
                <li
                  :class="[selectOrderItem === '租金贡献指数' ? 'active' : '']"
                  @click="selectSortClick(9010, '租金贡献指数')"
                >租金贡献指数</li>
              </ul>
            </span> -->
            <span style="height: 47px;">
              <el-checkbox-group
                v-model="orderList"
                @change="changeOrder"
              >
                <el-checkbox :label="8001">有联系方式参考</el-checkbox>
                <el-checkbox :label="10000">关注的品牌</el-checkbox>
                <el-checkbox :label="10001">已浏览品牌</el-checkbox>
                <el-checkbox :label="10002">已导出品牌</el-checkbox>
                <el-checkbox :label="10003">已消耗查看权益的品牌</el-checkbox>
              </el-checkbox-group>
            </span>
          </template>
          <div
            v-if="hasExportOut && hasExportOut.length > 0"
            class="down-brand-exel"
            :class="{'down-back-fix': backBtnFix}"
          >
            <span>
              <el-checkbox
                @change="getDownChange"
                v-model="downChecked"
              >全选</el-checkbox>
            </span>
            <span>
              已选 <i>{{downBrandNumber}}</i> 个品牌
            </span>
            <span
              @click="exportBrandToEnterprise"
              class="down-btn"
            >导出到企业品牌库</span>
          </div>
        </div>
      </div>
      <div
        class="condition-has"
        :style="{marginTop:(backBtnFix ?'50px': '0px')}"
        v-if="conditionHas.length > 0"
      >
        <div class="condition-has-box clearfix">
          <div class="fl condition-has-tit">已选条件</div>
          <div class="fl condition-has-con">
            <span
              class="condition-has-item"
              :key="key"
              v-for="(item, key) in conditionHas"
              @click="deleteCondition(item)"
            >{{item.text}}</span>
            <span
              @click="reloadPage"
              style="display: inline-block; padding-bottom: 10px"
            ><img
                src="../../assets/images/delete-condition-icon.png"
                style="vertical-align: middle; cursor: pointer;"
              /> <span class="condition-reset">清除重选</span></span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="brandIndex-tips"
      v-show="tipsHide"
    >
      <div class="tips-box">
        <i
          @click="closeTipsFunc"
          class="fr el-icon-close"
        ></i>
        <h3>赢商品牌指数注释</h3>
        <div class="content">
          <h4 class="title">赢商品牌指数：</h4>
          <p class="text">针对不同应用场景对品牌的筛选需求，赢商对品牌5个层面进行分级并指数化，该指数能以完全客观的视角去量化的评价品牌，帮助项目方快速客观评价品牌。</p>
          <h4 class="title">热度指数含义：</h4>
          <p class="text">反映品牌受市场关注的程度，指数越高，代表品牌在消费者和场所端的热度越高，进入新场子能快速带来客户。</p>
          <h4 class="title">潜力指数含义：</h4>
          <p class="text">反映品牌未来开店增长潜力程度，潜力只针对市场占有率还不高的连锁品牌。</p>
          <h4 class="title">营运指数含义：</h4>
          <p class="text">反映品牌在所属细分业态中，营运能力的强弱。营运指数越高，针对同一项目的同一位置，此品牌的租金支付能力相比于同细分业态的其他品牌更高。</p>
        </div>
      </div>
    </div> -->
    <brand-index
      v-if="tipsHide"
      @closeBrandIndex="closeBrandIndex"
    ></brand-index>
    <!-- <div class="CityList" id="srcollBox" v-if="user.version === '2.0' || user.version === '4.0'">
      <i class="el-icon-warning"></i>
      <div v-html="cityStr"></div>
    </div> -->
  </div>
</template>

<script>
import api from '../../api'
import { mapGetters } from 'vuex'
import SearchItem from './SearchItem'
import { changeTexture, checkParams } from '../../utils'
import Bus from '../../utils/bus.js'
import brandIndex from '@/components/brandIndexTips.vue'
export default {
  name: 'selfBrandSearch',
  components: {
    SearchItem: SearchItem,
    brandIndex: brandIndex
  },
  watch: {
    // 如果 `brandKeyWord` 发生改变，这个函数就会运行
    brandKeyWord: function (newQuestion, oldQuestion) {
      this.fetchBrand()
    },
    selfList: function (newQuestion) {
      this.downBrandNumber = 0
      this.downChecked = true
      for (const item of newQuestion.dataList) {
        if (!item.check) {
          this.downChecked = false
        } else {
          this.downBrandNumber++
        }
      }
      if (newQuestion.dataList.length === 0) {
        this.downChecked = false
      }
    }
  },
  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user',
      selfSearch: 'selfSearch',
      selfList: 'selfList'
    }),
    peoplePriceTypeNoCondition () {
      return this.peoplePriceTypeArr.length === 0
    },
    brandGradeTypeNoCondition () {
      return this.isNoCondition(this.brandGradeType)
    },
    brandClassTypeNoCondition () {
      return this.isNoCondition(this.brandClassType)
    },
    cooperationshipTypeNoCondition () {
      return this.isNoCondition(this.cooperationshipType)
    },
    dataTypeNoCondition () {
      return this.isNoCondition(this.dataType)
    },
    openLineNoCondition () {
      return this.isNoCondition(this.openLineType)
    },
    brandTagNoCondition () {
      return this.isNoCondition(this.brandTagType)
    },
    brandSpecialNoCondition () {
      return this.isNoCondition(this.brandSpecialType)
    },
    customLabelNoCondition () {
      return this.isNoCondition(this.selfTagType)
    },
    projectNumberNoCondition () {
      return this.isNoCondition(this.projectNumberType)
    },
    openHobbyNoCondition () {
      return this.isNoCondition(this.openHobbyType)
    },
    createCityTypeNoCondition () {
      return this.isNoCondition(this.createCityType)
    },
    shopTypeNoCondition () {
      return this.isNoCondition(this.shopType)
    },
    shopNumTypeNoCondition () {
      return this.isNoCondition(this.shopNumType)
    },
    areaTypeNoCondition () {
      return this.isNoCondition(this.areaType)
    },
    otherTypeNoCondition () {
      return this.isNoCondition(this.otherType)
    },
    businessTypeNoCondition () {
      return this.isNoCondition(this.businessType)
    },
    shopExpandPlanTypeNoCondition () {
      return this.isNoCondition(this.shopExpandPlanType)
    },
    businessDetailType () {
      /* eslint-disable */
      this.noCondition = true
      /* eslint-enable */
      // this.currentChildId = ''
      // console.log('this.businessType[this.currentId]', this.businessType[this.currentId])
      return (this.businessType[this.currentId] && changeTexture(this.businessType[this.currentId].child)) || []
    },
    businessDetailThreeType () {
      // console.log(this.currentChildKey)
      /* eslint-disable */
      this.businessDetailThree = []
      this.noConditionThree = true
      /* eslint-enable */
      return (!this.noBusinessCondition && this.businessDetailType[this.currentChildKey] && changeTexture(this.businessDetailType[this.currentChildKey].child)) || []
    },
    mainlandTypeNoCondition () {
      return this.isNoCondition(this.mainlandType)
    },
    mainlandDetailType () {
      /* eslint-disable */
      this.noCondition = true
      /* eslint-enable */
      return (this.mainlandType[this.currentMainlandId] && changeTexture(this.mainlandType[this.currentMainlandId].child)) || []
    },
    mainlandDetailThreeType () {
      // console.log(this.currentMainlandChildKey)
      /* eslint-disable */
      this.mainlandDetailThree = []
      this.noMainlandConditionThree = true
      /* eslint-enable */
      return (!this.noMainlandCondition && this.mainlandDetailType[this.currentMainlandChildKey] && changeTexture(this.mainlandDetailType[this.currentMainlandChildKey].child)) || []
    },
    cityLineDetailType () {
      // this.noConditionCity = true
      /* eslint-disable */
      this.currentChildCityId = ''
      /* eslint-enable */
      return (this.shopExpandPlanType[this.currentCityId] && changeTexture(this.shopExpandPlanType[this.currentCityId].child)) || []
    },
    showLocalBrandCity () {
      /* eslint-disable */
      let tem = false
      if (this.brandTagId.indexOf('1003') !== -1) {
        tem = true
      } else {
        this.checkLocalBrandCityList = []
      }
      /* eslint-enable */
      return tem
    }
  },
  props: {
    orderType: {
      type: Number
    },
    menuFilterId: {
      type: Number
    }
  },
  data () {
    return {
      currentBusinessType: '',
      currentSearhItem: -1,
      checkBusinessTagList: [],
      businessTagType: [],
      province_id: '',
      peoplePriceTypeArr: [],
      i: 0,
      peoplePriceType: [],
      localBrandCityType: [],
      checkLocalBrandCityList: [],
      brandSpecialType: [],
      selfTagType: [],
      brandGradeType: [],
      brandClassType: [],
      cooperationshipType: [],
      dataType: [],
      brandSpecialId: '',
      backBtnFix: false,
      brandLoad: true,
      currentId: 0,
      searchLoading: true,
      otherId: '',
      provinceId: '',
      noSelect: true,
      noSelectMainland: true,
      noBrandTagSelect: true,
      noSelectCity: true,
      shopNumId: '',
      brandTag: '',
      brandTagId: '',
      brandTagType: [],
      brandKeyWord: '',
      checkList: [],
      businessStateShow: false,
      businessState: 0,
      businessType: [],
      openHobbyType: [],
      areaType: [],
      // expandType: [],
      shopType: [],
      projectNumberType: [],
      openLineType: [],
      orderSingle: [],
      orderMultiple: [],
      shopNumType: [],
      createCityType: [],
      otherType: [],
      noCondition: true,
      currentChildId: '',
      brandKeyWordList: [],
      businessTypeId: '',
      businessId: '',
      areaId: '',
      expandId: '',
      projectNumberId: '',
      pageSize: 30,
      shopId: '',
      brandId: '',
      currentOrderType: 9001,
      canAddBrand: false,
      brandKeyWordListShow: false,
      orderHasChange: false,
      showBrandAttr: false,
      showExpandNeed: false,
      openLineId: '',
      openHobbyId: '',
      createCityId: '',
      shopExpandPlanType: [],
      shopExpandPlanId: '',
      noConditionCity: true,
      currentChildCityId: '',
      currentCityId: '',
      cityLineId: '',
      peoplePriceTypeId: '',
      noPeoplePrice: true,
      checkBusinessList: [],
      checkCityLineList: [],
      noBusinessCondition: true,
      conditionHas: [],
      showOpenShop: false,
      peoplePriceHas: [],
      noConditionThree: true,
      currentIdThree: [],
      businessDetailThree: [],
      currentChildKey: 0,
      massageList: [],
      marginLeft: 0,
      selfTagId: '',
      gradesId: '',
      classId: '',
      cooperationshipId: '',
      dataTypeId: '',
      cityStr: '',
      cityLen: 0,
      matchSize: 0,
      isSelectFollow: false,
      isFollow: false,
      followNum: 1,
      addUid: 0,
      orderItem: '品牌指数排序',
      selectOrderItem: '默认排序',
      orderIndex: [],
      sortShow: false,
      tipsHide: false,
      orderActive: false,
      indexFalse: false,
      downChecked: false,
      downBrandNumber: 0,
      loading: false,
      downList: [],
      orderSingle2: [],
      orderFilter: [],
      menuFilter: 9100,
      contactType: false,
      noOpenCheckedSel: true,
      openCityDetailList: [],
      openCityIdx: null,
      checkOpenCityList: [],
      selectOpenCityId: [],
      selectOpenCityLabel: [],
      orderList: [],
      selectSort: false,
      isHasDownExcel: true,
      isShowEditBrand: false,
      hasExportOut: [],
      hasLibrary: false,
      isOne: true,
      disabledCityIds: [],
      isLookBrand: 0,
      mainlandId: '',
      currentMainlandType: '', // 本土品牌
      mainlandType: [],
      currentMainlandId: 0,
      noMainlandCondition: true,
      noMainlandConditionThree: true,
      currentMainlandChildKey: 0,
      currentMainlandChildId: '',
      checkMainlandList: [],
      regionBrandCityId: [],
      orderOptions: [],
      radio_type: 9001,
      regionType: ''
    }
  },
  created () {
    this.currentBusinessType = this.$route.query.industryNames ? decodeURIComponent(this.$route.query.industryNames) : ''
    this.initData()
  },
  beforeDestroy () {
    window.removeEventListener('click', this.closeClick)
    window.removeEventListener('scroll', this.bodyScroll)
    window.removeEventListener('resize', this.bodyResize)
  },
  mounted () {
    // setTimeout(() => {
    //   if (this.user.usereditnum === 0) {
    //     this.isShowEditBrand = false
    //   } else {
    //     this.isShowEditBrand = true
    //   }
    // }, 400)
    this.axios.post(api.getBrandRights)
      .then((res) => {
        if (res.data.data.managingBrands === 0) {
          this.isShowEditBrand = false
        } else {
          this.isShowEditBrand = true
        }
      })
    this.$nextTick(() => {
      // 点击元素之外隐藏
      window.addEventListener('click', this.closeClick)
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      window.addEventListener('scroll', this.bodyScroll)
      window.addEventListener('resize', this.bodyResize)
      document.body.style.overflowY = 'auto'
    })
    const userAuthorities = this.user.confIds.filter(item => {
      return item.id === 164
    })
    this.hasExportOut = this.user.confIds.filter(item => {
      return item.id === 180
    })

    this.hasLibrary = userAuthorities[0].func
  },
  methods: {
    closeClick (e) {
      if (e.target.className !== 'el-icon-arrow-down' && e.target.className !== 'el-icon-arrow-up') {
        this.sortShow = false
        this.selectSort = false
      }
    },
    bodyScroll (e) {
      const scrollPosition = document.documentElement.clientHeight - window.pageYOffset
      if (scrollPosition < 50) {
        this.backBtnFix = true
      } else {
        this.backBtnFix = false
      }
    },
    bodyResize (e) {
      const scrollPosition = document.documentElement.clientHeight - window.pageYOffset
      if (this.$route.path === '/SelfBrandList' || this.$route.path === '/') {
        if (scrollPosition < 50) {
          this.backBtnFix = true
        } else {
          this.backBtnFix = false
        }
      }
    },
    getDownChange () {
      if (this.downChecked) {
        const list = JSON.parse(JSON.stringify(this.selfList))
        list.dataList = list.dataList.map((item) => {
          item.check = true
          return item
        })
        this.$store.dispatch('setSelfBrandList', list)
      } else {
        const list = JSON.parse(JSON.stringify(this.selfList))
        list.dataList = list.dataList.map((item) => {
          item.check = false
          return item
        })
        this.$store.dispatch('setSelfBrandList', list)
        this.downBrandNumber = 0
      }
    },
    exportBrandToEnterprise () {
      const list = JSON.parse(JSON.stringify(this.selfList))
      this.downList = []
      for (const item of list.dataList) {
        if (item.check) {
          const obj = {}
          obj.brandId = item.brandId
          obj.gbid = item.gbid
          this.downList.push(obj)
        }
      }
      if (this.downList.length > 0) {
        this.$confirm('是否确认将品牌导到企业品牌库?', '品牌导出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning',
          // center: true,
          iconClass: '',
          customClass: 'custom-export'
        }).then(() => {
          this.downAllExcel()
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请选择要导出的品牌！'
        })
      }
    },
    downAllExcel () {
      // const h = this.$createElement
      this.axios.post(api.exportBrandToEnterpriseBrandTable, this.downList)
        .then((res) => {
          if (res.data.code === 0) {
            const number = res.data.data
            const h = this.$createElement
            this.$msgbox({
              title: '品牌导出',
              message: h('p', null, [
                h('p', { style: 'color: #fff;' }, '导出成功，可在企业品牌库查看、管理'),
                h('p', { style: 'color: #fff; margin-top: 10px; padding: 0 50px;' }, number > 0 ? '注：其中' + number + '个品牌是未使用查看权益的，故拓展需求+联系方式数据无法导出，后续如有查看则可导出。' : '')
              ]),
              center: true,
              showCancelButton: false,
              confirmButtonText: '确定',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                } else {
                  done()
                }
              }
            })
          }
        })
    },
    exportBrandListFunc () {
      this.loading = true
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      this.exportBrandsDown().then(res => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const fileName = '品牌数据导出' + year + '-' + month + '-' + day + '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
          this.loading = false
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)

          this.loading = false
        }
      })
    },
    exportBrandsDown () {
      return this.axios({ // 用axios发送post请求
        method: 'post',
        url: api.getDownExcelList, // 请求地址
        data: this.downList, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    closeBrandIndex () {
      document.body.style.overflowY = 'auto'
      this.tipsHide = false
    },
    tipsShowFunc () {
      this.tipsHide = true
    },
    sortShowFunc () {
      this.sortShow = !this.sortShow
    },
    selectSortFunc () {
      this.selectSort = !this.selectSort
    },
    goMock () {
      if (this.user.projectName !== '') {
        if (this.matchType === 2) {
          const mockHref = this.$router.resolve({
            path: '/MockRoute',
            query: {
              source: 2
            }
          })
          window.open('/' + mockHref.href, '_blank')
        } else {
          this.isMatch = true
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请为账号绑定匹配项目后使用此功能！'
        })
      }
    },
    matchClick () {
      if (this.user.projectName !== '') {
        if (this.matchType === 2) {
          const mockHref = this.$router.resolve({
            path: '/MockRoute',
            query: {
              source: 2
            }
          })
          window.open('/' + mockHref.href, '_blank')
        } else {
          this.isMatch = true
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请为账号绑定匹配项目后使用此功能！'
        })
      }
    },
    againMatch () {
      if (this.user.projectName !== '') {
        if (this.matchType === 2) {
          const mockHref = this.$router.resolve({
            path: '/MockRoute',
            query: {
              source: 2
            }
          })
          window.open('/' + mockHref.href, '_blank')
        } else {
          this.isMatch = true
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请为账号绑定匹配项目后使用此功能！'
        })
      }
    },
    getMessageList () {
      this.axios.post(api.getMessageList, { pageNum: '1', pageSize: '3' })
        .then((res) => {
          if (res.data.code === 0) {
            this.massageList = res.data.data.list
          }
        })
    },
    changeData (type) {
      this.brandKeyWordListShow = true
      if (type === 'down') {
        if (this.currentSearhItem < this.brandKeyWordList.length - 1) {
          this.currentSearhItem++
        } else {
          this.currentSearhItem = 0
        }
      }
      // console.log('1', this.currentSearhItem)
      if (type === 'up') {
        if (this.currentSearhItem === 0) {
          this.currentSearhItem = this.brandKeyWordList.length - 1
        } else {
          this.currentSearhItem--
        }
      }
      // console.log('2', this.currentSearhItem)
      this.brandKeyWord = this.brandKeyWordList[this.currentSearhItem].brandName
      // console.log(type)
      this.jump()
    },
    jump () {
      // let jump = document.querySelectorAll('.d_jump')
      // 获取需要滚动的距离
      const total = 30 * this.currentSearhItem
      // Chrome
      document.querySelector('#searchBar').scrollTop = total
      // Firefox
      document.querySelector('#searchBar').scrollTop = total
      // Safari
      document.querySelector('#searchBar').pageYOffset = total
    },
    againAllList () {
      const params = {
        pageNum: 1,
        brand_name: '',
        loading: true,
        brand_label: this.brandTagId || '',
        // benchmark_city_level: '',
        inCityIds: '',
        benchmark_shop_num: '',
        benchmark_floor_preference: '',
        attribute_formats_id: this.businessId || '',
        attribute_founded_id: '',
        attribute_kaidian_id: '',
        operation_feature: '',
        attribute_consumption_id: this.peoplePriceId,
        demand_plan_shop_num: '',
        demand_plan_expand_city: '',
        demand_area: '',
        demand_plan_expand_province: '',
        other_contact: '',
        brandGrades: '',
        addUid: this.addUid,
        menuFilter: this.menuFilter,
        dataType: this.dataTypeId,
        radio_type: 9001,
        regionType: this.regionType
      }
      if (this.$route.query.from === 'myfollow') {
        if (this.user.version === '4.0') {
          params.focusBrand = 1
          this.orderList.push(10000)
        } else {
          if (this.user.version === '2.0') {
            this.currentOrderType = 9102
            params.menuFilter = 9102
          } else {
            this.currentOrderType = 9005
          }
          this.orderFilter.map((item) => {
            if (item.id === this.currentOrderType) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
        }
      }
      if (this.$route.query.from === 'cooperation') {
        if (this.user.version === '2.0') {
          this.currentOrderType = 9101
          params.menuFilter = 9101
          this.orderFilter.map((item) => {
            if (item.id === this.currentOrderType) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
        } else {
          params.cooperativeBrand = 1
          this.orderList.push(10001)
        }
      }
      // if (checkObjChange(params, this.selfSearch) || this.orderHasChange) {
      this.$store.dispatch('setSelfSearch', params)
      this.axios.post(api.selfBrandList, checkParams(this.selfSearch, this.currentOrderType))
        .then((res) => {
          if (res.data.code === 0) {
            const dataObj = res.data.data
            dataObj.dataList = res.data.data.dataList.map((item) => {
              item.check = false
              return item
            })
            this.$store.dispatch('setSelfBrandList', dataObj)
          } else {
            // this.$message.error(res.data.message)
          }
          const params = {
            loading: false
          }
          this.$store.dispatch('setSelfSearch', params)
        })
    },
    reloadPage () {
      this.conditionHas = []
      this.$router.push({
        path: '/SelfBrandList'
      }).then(() => {
        // 先这样处理
        window.location.reload()
        document.documentElement.scrollTop = 0
      })
    },
    isNoCondition (type) {
      let tem = true
      type.map((item) => {
        if (item.active) {
          tem = false
        }
        return item
      })
      return tem
    },
    // 获取业态标签
    fetchBusinessTag (formatId) {
      // this.peoplePriceType = this.businessType[this.currentId].price
      // this.axios.post(api.selfBusinessTag, { 'formatId': formatId })
      // .then((res) => {
      //   console.log(res.data.data)
      //   this.businessTagType = changeTexture(res.data.data)
      // })
    },
    deleteCondition (items) {
      console.log('sssssss', items)
      // 删除当前点击条件 后面优化，代码不能这么写
      const temArr = []
      this.conditionHas.map((item) => { // 去掉已选条件对应内容
        if (item.id !== items.id) {
          temArr.unshift(item)
        }
        return item
      })
      this.conditionHas = temArr
      // 将选中项目的状态置为false
      if (items.type === 'brandTag') {
        this.resetCheckList(this.brandTagType, items.id, (value) => {
          this.brandTagId = value
        })
        if (items.id === 1003) {
          this.checkLocalBrandCityList = []
        }
      } else if (items.type === 'busi-two') {
        this.businessId = items.text.split('-').filter(item => {
          return item !== items.id
        })[0]
        this.resetCheckList(this.businessDetailType, items.id, (value) => {
          this.selectNoBusinessCondition()
          this.currentChildId = value
        })
      } else if (items.type === 'busi-three') {
        this.businessDetailThree = this.businessDetailThree.filter((item) => {
          return item !== items.id
        })
        if (this.businessDetailThree.length === 0) {
          this.conditionHas.push({
            id: this.businessDetailType[this.currentChildKey].id,
            text: this.businessDetailType[this.currentChildKey].item + '-' + this.businessType[this.currentId].item,
            type: 'busi-two'
          })
        }
        this.businessId = items.text.split('-').filter(item => {
          return item !== items.id
        })[0]
      } else if (items.type === 'main-two') {
        this.mainlandId = items.text.split('-').filter(item => {
          return item !== items.id
        })[0]
        this.resetCheckList(this.mainlandDetailType, items.id, (value) => {
          this.selectNoMainlandCondition()
          this.currentMainlandChildId = value
        })
      } else if (items.type === 'main-three') {
        this.mainlandDetailThree = this.mainlandDetailThree.filter((item) => {
          return item !== items.id
        })
        if (this.mainlandDetailThree.length === 0) {
          this.conditionHas.push({
            id: this.mainlandDetailType[this.currentMainlandChildKey].id,
            text: this.mainlandDetailType[this.currentMainlandChildKey].item + '-' + this.mainlandType[this.currentMainlandId].item,
            type: 'main-two'
          })
        }
        this.mainlandId = items.text.split('-').filter(item => {
          return item !== items.id
        })[0]
      } else if (items.type === 'brandSpecial') {
        this.resetCheckList(this.brandSpecialType, items.id, (value) => {
          this.brandSpecialId = value
        })
      } else if (items.type === 'openLine') {
        // this.resetCheckList(this.openLineType, items.id, (value) => {
        //   this.openLineId = value
        // })
        this.checkOpenCityList = this.checkOpenCityList.filter((item) => {
          return item !== items.id
        })
        const cityName = items.text.split('(')[0]
        this.selectOpenCityLabel = this.selectOpenCityLabel.filter((item) => {
          return item !== cityName
        })
        this.openLineId = this.checkOpenCityList.join(',')
      } else if (items.type === 'projectNumber') {
        this.resetCheckList(this.projectNumberType, items.id, (value) => {
          this.projectNumberId = value
        })
      } else if (items.type === 'openHobby') {
        this.resetCheckList(this.openHobbyType, items.id, (value) => {
          this.openHobbyId = value
        })
      } else if (items.type === 'peoplePrice') {
        this.resetCheckList(this.peoplePriceType, items.id, (value) => {
          this.peoplePriceId = value
        })
      } else if (items.type === 'createCity') {
        this.resetCheckList(this.createCityType, items.id, (value) => {
          this.createCityId = value
        })
      } else if (items.type === 'shop') {
        this.resetCheckList(this.shopType, items.id, (value) => {
          this.shopId = value
        })
      } else if (items.type === 'shopNum') {
        this.resetCheckList(this.shopNumType, items.id, (value) => {
          this.shopNumId = value
        })
      } else if (items.type === 'area') {
        this.resetCheckList(this.areaType, items.id, (value) => {
          this.areaId = value
        })
      } else if (items.type === 'other') {
        this.resetCheckList(this.otherType, items.id, (value) => {
          this.otherId = value
        })
      } else if (items.type === 'dataType') {
        this.resetCheckList(this.dataType, items.id, (value) => {
          this.dataTypeId = value
        })
        this.dataType = this.dataType.map((item) => {
          item.active = false
          return item
        })
      } else if (items.type.indexOf('business') !== -1) {
        console.log(items.type, ('business' + this.currentId))
        this.checkBusinessTagList = []
        const temBusinessArr = []
        // 如果是当前选中一级
        if (items.type === ('business' + this.currentId)) {
          // 设置当前一级的二级选中状态
          const temArr = []
          this.checkBusinessList.map((item) => {
            if (item !== items.id) {
              temArr.push(item)
            }
            return item
          })
          this.checkBusinessList = temArr
          // 设置一级选中数量显示
          this.businessType.map((item, key) => {
            if (this.currentId === key) {
              item.hint = this.checkBusinessList.join(',')
            }
            // 获取所有选中二级id
            if (item.hint) {
              temBusinessArr.push(item.hint)
            }
            return item
          })
          if (this.checkBusinessList.length === 0) {
            if (temBusinessArr.length === 0) {
              this.businessId = ',' + this.businessType[this.currentId].id
              this.addCheckList('business', [this.businessType[this.currentId].id], [this.businessType[this.currentId].item])
            } else {
              this.businessId = ',' + temBusinessArr.join(',') + ',' + this.businessType[this.currentId].id
              this.addCheckList('business', [this.businessType[this.currentId].id], [this.businessType[this.currentId].item])
            }
          } else {
            this.businessId = ',' + temBusinessArr.join(',')
          }
          // 当前删除项不是当前切换的一级内容
        } else {
          const noCurrentId = parseInt(items.type.slice(8))
          console.log(noCurrentId)
          let delIndex = 0
          this.businessType.map((item, key) => {
            if (noCurrentId === key) {
              const temArr = []
              // 删除当前选中项ID
              item.hint && item.hint.split(',').map((item) => {
                if (parseInt(item) !== items.id) {
                  temArr.push(item)
                }
                return item
              })
              item.hint = temArr.join(',')
            }
            if (items.id === item.id) {
              delIndex = key
            }
            // 获取所有选中二级id
            if (item.hint) {
              temBusinessArr.push(item.hint)
            }
            return item
          })
          if (this.checkBusinessList.length !== 0) {
            this.businessId = ',' + temBusinessArr.join(',')
          } else {
            if (temBusinessArr.length === 0) {
              let hasBusiId = ''
              const temArr = []
              this.conditionHas.map((item) => {
                temArr.push(item.id)
                return item
              })
              this.businessType.map((item) => {
                if (temArr.join(',').indexOf(item.id) !== -1) {
                  hasBusiId = item.id
                  this.businessId = ',' + item.id
                  item.active = true
                }
                return item
              })
              if (!hasBusiId) {
                this.businessId = ','
                // 一级不限
                this.businessType.map((item, key) => {
                  item.active = false
                  return item
                })
                this.noSelect = true
              }
            } else {
              if (this.currentId === delIndex) {
                this.businessType[this.currentId].active = false
                let nearKey = 0
                this.businessType.map((item, key) => {
                  if (item.hint) {
                    nearKey = key
                  }
                  return item
                })
                if (nearKey) {
                  this.currentId = nearKey
                  this.businessType[nearKey].active = true
                  this.checkBusinessList = this.businessType[nearKey].hint.split(',') || []
                  this.checkBusinessList = this.checkBusinessList.map((item) => {
                    return parseInt(item)
                  })
                }
                this.businessId = ',' + temBusinessArr.join(',')
              } else {
                this.businessId = ',' + temBusinessArr.join(',') + ',' + this.businessType[this.currentId].id
              }
            }
          }
        }
        this.noBusinessCondition = this.checkBusinessList.length === 0
        const temArrbba = []
        this.conditionHas.map((item) => {
          if (item.type !== 'busi-nessTag') {
            temArrbba.unshift(item)
          }
          return item
        })
        this.conditionHas = temArrbba
      } else if (items.type.indexOf('mainland') !== -1) {
        console.log('111', items)
        console.log(items.type, ('mainland' + this.currentMainlandId))
        const temBusinessArr = []
        // 如果是当前选中一级
        if (items.type === ('mainland' + this.currentMainlandId)) {
          // 设置当前一级的二级选中状态
          const temArr = []
          this.checkMainlandList.map((item) => {
            if (item !== items.id) {
              temArr.push(item)
            }
            return item
          })
          this.checkMainlandList = temArr
          // 设置一级选中数量显示
          this.mainlandType.map((item, key) => {
            if (this.currentMainlandId === key) {
              item.hint = this.checkMainlandList.join(',')
            }
            // 获取所有选中二级id
            if (item.hint) {
              temBusinessArr.push(item.hint)
            }
            return item
          })
          if (this.checkMainlandList.length === 0) {
            if (temBusinessArr.length === 0) {
              this.mainlandId = ',' + this.mainlandType[this.currentMainlandId].id
              this.addCheckList('mainland', [this.mainlandType[this.currentMainlandId].id], [this.mainlandType[this.currentMainlandId].item])
            } else {
              this.mainlandId = ',' + temBusinessArr.join(',') + ',' + this.mainlandType[this.currentMainlandId].id
              this.addCheckList('mainland', [this.mainlandType[this.currentMainlandId].id], [this.mainlandType[this.currentMainlandId].item])
            }
          } else {
            this.mainlandId = ',' + temBusinessArr.join(',')
          }
        } else {
          const noCurrentId = parseInt(items.type.slice(8))
          console.log(noCurrentId)
          let delIndex = 0
          this.mainlandType.map((item, key) => {
            if (noCurrentId === key) {
              const temArr = []
              // 删除当前选中项ID
              item.hint && item.hint.split(',').map((item) => {
                if (parseInt(item) !== items.id) {
                  temArr.push(item)
                }
                return item
              })
              item.hint = temArr.join(',')
            }
            if (items.id === item.id) {
              delIndex = key
            }
            // 获取所有选中二级id
            if (item.hint) {
              temBusinessArr.push(item.hint)
            }
            return item
          })
          if (this.checkMainlandList.length !== 0) {
            this.mainlandId = ',' + temBusinessArr.join(',')
          } else {
            if (temBusinessArr.length === 0) {
              let hasBusiId = ''
              const temArr = []
              this.conditionHas.map((item) => {
                temArr.push(item.id)
                return item
              })
              this.mainlandType.map((item) => {
                if (temArr.join(',').indexOf(item.id) !== -1) {
                  hasBusiId = item.id
                  this.mainlandId = ',' + item.id
                  item.active = true
                }
                return item
              })
              if (!hasBusiId) {
                this.mainlandId = ','
                // 一级不限
                this.mainlandType.map((item, key) => {
                  item.active = false
                  return item
                })
                this.noSelectMainland = true
              }
            } else {
              if (this.currentMainlandId === delIndex) {
                this.mainlandType[this.currentMainlandId].active = false
                let nearKey = 0
                this.mainlandType.map((item, key) => {
                  if (item.hint) {
                    nearKey = key
                  }
                  return item
                })
                if (nearKey) {
                  this.currentMainlandId = nearKey
                  this.mainlandType[nearKey].active = true
                  this.checkMainlandList = this.mainlandType[nearKey].hint.split(',') || []
                  this.checkMainlandList = this.checkMainlandList.map((item) => {
                    return parseInt(item)
                  })
                }
                this.mainlandId = ',' + temBusinessArr.join(',')
              } else {
                this.mainlandId = ',' + temBusinessArr.join(',') + ',' + this.mainlandType[this.currentMainlandId].id
              }
            }
          }
        }
        this.noMainlandCondition = this.checkMainlandList.length === 0
        const temArrbba = []
        this.conditionHas.map((item) => {
          if (item.type !== 'busi-nessTag') {
            temArrbba.unshift(item)
          }
          return item
        })
        this.conditionHas = temArrbba
      } else if (items.type.indexOf('cityLine') !== -1) {
        const temBusinessArr = []
        // 如果是当前选中一级
        if (items.type === ('cityLine' + this.currentCityId)) {
          // 设置当前一级的二级选中状态
          const temArr = []
          this.checkCityLineList.map((item) => {
            if (item !== items.id) {
              temArr.push(item)
            }
            return item
          })
          this.checkCityLineList = temArr
          // 设置一级选中数量显示
          this.shopExpandPlanType.map((item, key) => {
            if (this.currentCityId === key) {
              item.hint = this.checkCityLineList.join(',')
            }
            // 获取所有选中二级id
            if (item.hint) {
              temBusinessArr.push(item.hint)
            }
            return item
          })
          if (this.checkCityLineList.length === 0) {
            if (temBusinessArr.length === 0) {
              this.cityLineId = ','
              this.provinceId = this.shopExpandPlanType[this.currentCityId].id
              this.addCheckList('cityLine', [this.shopExpandPlanType[this.currentCityId].id], [this.shopExpandPlanType[this.currentCityId].item])
            } else {
              this.cityLineId = ',' + temBusinessArr.join(',')
              this.provinceId = this.shopExpandPlanType[this.currentCityId].id
              this.addCheckList('cityLine', [this.shopExpandPlanType[this.currentCityId].id], [this.shopExpandPlanType[this.currentCityId].item])
            }
          } else {
            this.cityLineId = ',' + temBusinessArr.join(',')
          }
          // 当前删除项不是当前切换的一级内容
        } else {
          const nocurrentCityId = parseInt(items.type.slice(8))
          let delIndex = 0
          this.shopExpandPlanType.map((item, key) => {
            if (nocurrentCityId === key) {
              const temArr = []
              // 删除当前选中项ID
              item.hint && item.hint.split(',').map((item) => {
                if (parseInt(item) !== parseInt(items.id)) {
                  temArr.push(item)
                }
                return item
              })
              item.hint = temArr.join(',')
            }
            if (items.id === item.id) {
              delIndex = key
            }
            // 获取所有选中二级id
            if (item.hint) {
              temBusinessArr.push(item.hint)
            }
            return item
          })
          if (this.checkCityLineList.length !== 0) {
            this.cityLineId = ',' + temBusinessArr.join(',')
          } else {
            if (temBusinessArr.length === 0) {
              let hasBusiId = ''
              const temArr = []
              this.conditionHas.map((item) => {
                temArr.push(item.id)
                return item
              })
              this.shopExpandPlanType.map((item) => {
                if (temArr.join(',').indexOf(item.id) !== -1) {
                  hasBusiId = item.id
                  this.provinceId = item.id
                  item.active = true
                }
                return item
              })
              if (!hasBusiId) {
                this.cityLineId = ','
                this.provinceId = ''
                // 一级不限
                this.shopExpandPlanType.map((item, key) => {
                  item.active = false
                  return item
                })
                this.noSelectCity = true
              } else {
                this.cityLineId = ',' + temBusinessArr.join(',')
              }
            } else {
              if (this.currentCityId === delIndex) {
                this.shopExpandPlanType[this.currentCityId].active = false
                let nearKey = 0
                this.shopExpandPlanType.map((item, key) => {
                  if (item.hint) {
                    nearKey = key
                  }
                  return item
                })
                if (nearKey) {
                  this.currentCityId = nearKey
                  this.shopExpandPlanType[nearKey].active = true
                  this.checkCityLineList = this.shopExpandPlanType[nearKey].hint.split(',') || []
                  this.checkCityLineList = this.checkCityLineList.map((item) => {
                    return parseInt(item)
                  })
                }
                this.cityLineId = ',' + temBusinessArr.join(',')
                this.provinceId = ''
              } else {
                this.cityLineId = ',' + temBusinessArr.join(',')
                this.provinceId = this.shopExpandPlanType[this.currentCityId].id
              }
            }
          }
        }
        this.noConditionCity = this.checkCityLineList.length === 0
      } else if (items.type === 'busi-nessTag') {
        // 热门业态标签
        const temArra = []
        for (const iterator of this.checkBusinessTagList) {
          if (iterator !== items.id) {
            temArra.push(iterator)
          }
        }
        this.checkBusinessTagList = temArra
      } else if (items.type === 'selfTag') {
        this.resetCheckList(this.selfTagType, items.id, (value) => {
          this.selfTagId = value
        })
      } else if (items.type === 'brandGrade') {
        this.resetCheckList(this.brandGradeType, items.id, (value) => {
          this.gradesId = value
        })
      } else if (items.type === 'brandClass') {
        this.resetCheckList(this.brandClassType, items.id, (value) => {
          this.classId = value
        })
      } else if (items.type === 'cooperationship') {
        this.resetCheckList(this.cooperationshipType, items.id, (value) => {
          this.cooperationshipId = value
        })
      }
      this.fetchListData()
      Bus.$emit('changeCheckList')
    },
    selectNoBusinessCondition () {
      this.currentChildId = ''
      const businessOneId = this.businessDetailType.map((item) => {
        return item.id
      })
      this.checkBusinessTagList = []
      this.fetchBusinessTag(businessOneId.join(','))
      this.noBusinessCondition = true
      this.checkBusinessList = []
      let temBusinessStr = ''
      this.businessType.map((item, key) => {
        if (this.currentId === key) {
          item.hint = ''
        }
        if (item.hint) {
          temBusinessStr += ',' + item.hint
        }
        return item
      })
      // console.log('currentId', this.currentId)
      this.businessId = this.businessType[this.currentId].id + temBusinessStr
      this.fetchListData()
      const temArr = []
      // let temName = 'business' + this.currentId
      this.conditionHas.map((item) => {
        if (item.type !== 'busi-two' && item.type !== 'busi-three') {
          temArr.unshift(item)
        }
        return item
      })
      this.conditionHas = temArr
      this.addCheckList('business', [this.businessType[this.currentId].id], [this.businessType[this.currentId].item])
    },
    selectNoMainlandCondition () {
      this.currentMainlandChildId = ''
      this.noMainlandCondition = true
      this.checkMainlandList = []
      let temBusinessStr = ''
      this.mainlandType.map((item, key) => {
        if (this.currentMainlandId === key) {
          item.hint = ''
        }
        if (item.hint) {
          temBusinessStr += ',' + item.hint
        }
        return item
      })
      this.mainlandId = this.mainlandType[this.currentMainlandId].id + temBusinessStr
      const temArr = []
      this.conditionHas.map((item) => {
        // console.log(item)
        if (item.type !== 'main-two' && item.type !== 'main-three') {
          temArr.unshift(item)
        }
        return item
      })
      this.conditionHas = temArr
      this.conditionHas.unshift({ id: this.mainlandType[this.currentMainlandId].id, text: this.mainlandType[this.currentMainlandId].item, type: 'mainland' })
      this.fetchListData()
    },
    addCheckList (type, arrId, arrText) {
      // console.log(type, arrId, arrText)
      const temCurrentArr = []
      const temLastArr = []
      // 先后选中，排序问题，优化
      this.conditionHas.map((item) => {
        if (type !== item.type) {
          temLastArr.push(item)
        }
        return item
      })
      this.conditionHas = temLastArr
      arrId.map((item, index) => {
        let temText = arrText[index]
        if (type === 'projectNumber') {
          temText = temText + '(开店数量)'
        } else if (type === 'shopNum') {
          temText = temText + '(计划开店)'
        } else if (type === 'brandTag') {
          const localCityTem = []
          this.province_id = this.province_id || this.user.province_id + ''
          for (const iterator of this.localBrandCityType) {
            if (this.province_id.indexOf(iterator.id + '') !== -1) {
              localCityTem.push(iterator.item)
            }
          }
          if (item === 1003) {
            if (localCityTem.length > 0) {
              temText = '区域品牌(' + localCityTem.join('、') + ')'
            } else {
              temText = '区域品牌'
            }
          }
        } else if (type === 'openLine') {
          temText = temText + '(开店城市)'
        } else if (type === 'peoplePrice') {
          temText = temText + '(' + this.businessType[this.currentId].item + ')'
        } else if (type === 'cityLine') {
          // temText = temText + '(' + this.shopExpandPlanType[this.currentCityId].item + ')'
          temText = this.shopExpandPlanType[this.currentCityId].item
          // console.log(temText)
        } else if (type === 'business') {
          // temText = temText + '(' + this.businessType[this.currentId].item + ')'
          temText = this.businessType[this.currentId].item
        } else if (type === 'mainland') {
          temText = this.mainlandType[this.currentMainlandId].item
        }
        temCurrentArr.unshift({ id: item, text: temText, type: type })
        return item
      })
      temCurrentArr.map((item) => {
        this.conditionHas.unshift(item)
        return item
      })
      // console.log('temCurrentArr', temCurrentArr)
    },
    selectBusinessTagMoreSure () {
      setTimeout(() => {
        // console.log(this.businessDetailThree)
        const temName = 'busi-three'
        const temid = []
        const temname = []
        const temSte = this.businessDetailThree.join(',')
        this.businessDetailThreeType.map((item) => {
          if (temSte.indexOf(item.id + '') !== -1) {
            temid.push(item.id)
            temname.push(item.item + '-' + this.businessDetailType[this.currentChildKey].item + '-' + this.businessType[this.currentId].item)
          }
          return item
        })
        this.addCheckList(temName, temid, temname)
        // 如果无三级业态，则显示二级选中
        if (this.businessDetailThree.length === 0) {
          this.conditionHas.push({
            id: this.businessDetailType[this.currentChildKey].id,
            text: this.businessDetailType[this.currentChildKey].item + '-' + this.businessType[this.currentId].item,
            type: 'busi-two'
          })
          this.businessId = this.businessDetailType[this.currentChildKey].item
        } else {
          this.conditionHas = this.conditionHas.filter((item) => {
            return item.type !== 'busi-two'
          })
          this.businessId = temSte
        }
        this.fetchListData()
      }, 0)
    },
    // 本土品牌三级筛选
    selectMainlandTagMoreSure () {
      // mainland
      setTimeout(() => {
        // console.log(this.mainlandDetailThree)
        const temName = 'main-three'
        const temid = []
        const temname = []
        const temSte = this.mainlandDetailThree.join(',')
        this.mainlandDetailThreeType.map((item) => {
          if (temSte.indexOf(item.id + '') !== -1) {
            temid.push(item.id)
            temname.push(item.item + '-' + this.mainlandDetailType[this.currentMainlandChildKey].item + '-' + this.mainlandType[this.currentMainlandId].item)
          }
          return item
        })
        // console.log('this.mainlandDetailThreeType', this.mainlandDetailThreeType)
        this.addCheckList(temName, temid, temname)
        // 如果无三级业态，则显示二级选中
        if (this.mainlandDetailThree.length === 0) {
          this.conditionHas.push({
            id: this.mainlandDetailType[this.currentMainlandChildKey].id,
            text: this.mainlandDetailType[this.currentMainlandChildKey].item + '-' + this.mainlandType[this.currentMainlandId].item,
            type: 'main-two'
          })
          this.mainlandId = this.mainlandDetailType[this.currentMainlandChildKey].item
        } else {
          this.conditionHas = this.conditionHas.filter((item) => {
            return item.type !== 'main-two'
          })
          this.mainlandId = temSte
        }
        this.fetchListData()
      }, 0)
    },
    selectLocalBrandCityMoreSure () {
      setTimeout(() => {
        this.province_id = this.checkLocalBrandCityList.join(',')
        const localCityTem = []
        for (const iterator of this.localBrandCityType) {
          if (this.province_id.indexOf(iterator.id + '') !== -1) {
            localCityTem.push(iterator.item)
          }
        }
        for (const iterator of this.conditionHas) {
          if (iterator.id === 1003) {
            if (localCityTem.length > 0) {
              iterator.text = '区域品牌(' + localCityTem.join('、') + ')'
            } else {
              iterator.text = '区域品牌'
            }
          }
        }
        this.fetchListData()
      }, 0)
    },
    selectNoConditionCity () {
      this.noConditionCity = true
      this.checkCityLineList = []
      let temCityStr = ''
      this.shopExpandPlanType.map((item, key) => {
        if (this.currentCityId === key) {
          item.hint = ''
        }
        if (item.hint) {
          item.child.map((items1) => {
            if (item.hint.indexOf(items1.id) !== -1) {
              temCityStr += ',' + items1.pid
            }
          })
        }
        return item
      })
      this.cityLineId = temCityStr
      this.provinceId = this.shopExpandPlanType[this.currentCityId].id
      this.fetchListData()
      const temArr = []
      const temName = 'cityLine' + this.currentCityId
      this.conditionHas.map((item) => {
        if (temName !== item.type) {
          temArr.unshift(item)
        }
        return item
      })
      this.conditionHas = temArr
      this.addCheckList('cityLine', [this.shopExpandPlanType[this.currentCityId].id], [this.shopExpandPlanType[this.currentCityId].item])
    },
    selectCityMoreSure () {
      setTimeout(() => {
        this.noConditionCity = false
        const temArr = []
        const temName = 'cityLine' + this.currentCityId
        let temCityStr = ''
        this.shopExpandPlanType.map((item, key) => {
          if (this.currentCityId === key) {
            item.hint = this.checkCityLineList.join(',')
            item.child.map((items) => {
              if (item.hint.indexOf(items.id) !== -1) {
                temArr.push(items.item + '(' + this.shopExpandPlanType[this.currentCityId].item + ')')
              }
              return items
            })
          }
          if (item.hint) {
            item.child.map((items1) => {
              if (item.hint.indexOf(items1.id) !== -1) {
                temCityStr += ',' + items1.id
              }
            })
            this.provinceId = ''
          }
          return item
        })
        // this.cityLineId = temCityStr
        if (this.checkCityLineList.length !== 0) {
          this.cityLineId = temCityStr
          const temHasArr = []
          // 当选择二级后，将一级的条件去除
          this.conditionHas.map((item) => {
            if (item.id !== this.shopExpandPlanType[this.currentCityId].id) {
              temHasArr.unshift(item)
            }
            return item
          })
          this.conditionHas = temHasArr
        } else {
          if (temCityStr.length === 0) {
            this.cityLineId = ','
            this.provinceId = this.shopExpandPlanType[this.currentCityId].id
          } else {
            this.cityLineId = temCityStr + ',' + this.shopExpandPlanType[this.currentCityId].id
            const temHasArr = []
            // 当选择二级后，将一级的条件去除
            this.conditionHas.map((item) => {
              if (item.id !== this.shopExpandPlanType[this.currentCityId].id) {
                temHasArr.unshift(item)
              }
              return item
            })
            this.conditionHas = temHasArr
          }
          this.noConditionCity = true
          this.addCheckList('cityLine', [this.shopExpandPlanType[this.currentCityId].id], [this.shopExpandPlanType[this.currentCityId].item])
        }
        this.fetchListData()
        const temSte = this.checkCityLineList.join(',')
        const temid = []
        const temname = []
        this.cityLineDetailType.map((item) => {
          if (temSte.indexOf(item.id + '') !== -1) {
            temid.push(item.id)
            temname.push(item.item + '(' + this.shopExpandPlanType[this.currentCityId].item + ')')
          }
          return item
        })
        this.addCheckList(temName, temid, temname)
      }, 0)
    },
    showExpandNeedHandler () {
      this.showExpandNeed = !this.showExpandNeed
    },
    showBrandAttrHandler () {
      this.showBrandAttr = !this.showBrandAttr
    },
    showOpenShopHandler () {
      this.showOpenShop = !this.showOpenShop
    },
    brandShow () {
      if (this.brandKeyWord) {
        this.fetchBrand()
        this.brandKeyWordListShow = true
      }
    },
    brandHide () {
      if (this.brandKeyWordList.length > 0) {
        this.brandKeyWordListShow = false
      }
    },
    fetchListData (isScroll) {
      this.brandKeyWordListShow = false
      this.currentSearhItem = -1
      // 试用用户
      if (this.user.type === '10') {
        this.$message({
          type: 'warning',
          message: '您暂无使用权限，如需查看，请联系客服微信号：win18578675071',
          duration: 3000
        })
        return
      }
      this.i++
      let brandKeyWord = ''
      if (this.brandKeyWord !== '') {
        brandKeyWord = this.brandKeyWord
      }
      this.province_id = this.checkLocalBrandCityList.join(',')
      if (this.businessId === ',') {
        this.businessId = ''
      }
      this.regionBrandCityId = []
      // console.log(this.conditionHas)
      // console.log(this.mainlandType)
      for (const item of this.conditionHas) {
        if (item.type === 'mainland') {
          const area = this.mainlandType.find((options) => {
            return options.id === item.id
          })
          for (const c1 of area.child) {
            for (const c2 of c1.child) {
              this.regionBrandCityId.push(c2.id)
            }
          }
        } else if (item.type === 'main-two') {
          const area = this.mainlandDetailType.find((options) => {
            return options.id === item.id
          })
          // console.log(area)
          for (const c1 of area.child) {
            this.regionBrandCityId.push(c1.id)
          }
        } else if (item.type === 'main-three') {
          this.regionBrandCityId.push(item.id)
        }
      }
      // console.log(this.regionBrandCityId)
      // console.log(this.radio_type)
      const params = {
        regionBrandCityId: this.regionBrandCityId,
        pageNum: 1,
        brand_name: brandKeyWord,
        loading: true,
        brand_label: this.brandTagId,
        operation_feature: this.brandSpecialId,
        inCityIds: this.openLineId,
        benchmark_shop_num: this.projectNumberId,
        province_id: this.province_id,
        format_tagname: this.checkBusinessTagList.join(','),
        benchmark_floor_preference: this.openHobbyId,
        attribute_formats_id: this.businessId,
        attribute_founded_id: this.createCityId,
        attribute_kaidian_id: this.shopId,
        attribute_consumption_id: this.peoplePriceId,
        demand_plan_shop_num: this.shopNumId,
        demand_plan_expand_city: this.cityLineId.slice(1),
        demand_area: this.areaId,
        demand_plan_expand_province: this.provinceId,
        other_contact: this.otherId,
        customLabel: this.selfTagId,
        brandGrades: this.gradesId,
        brandClass: this.classId,
        cooperationship: this.cooperationshipId,
        addUid: this.addUid,
        menuFilter: this.menuFilter,
        focusBrand: this.orderList.indexOf(10000) > -1 ? 1 : 0,
        exportBrand: this.orderList.indexOf(10002) > -1 ? 1 : 0,
        viewedBrand: this.orderList.indexOf(10001) > -1 ? 1 : 0,
        lookBrand: this.isLookBrand,
        radio_type: this.radio_type,
        regionType: this.regionType
      }
      this.$store.dispatch('setSelfSearch', params)
      this.axios.post(api.selfBrandList, checkParams(this.selfSearch, this.currentOrderType))
        .then((res) => {
          if (res.data.code === 0) {
            this.$store.dispatch('setSelfBrandList', res.data.data)
            if (this.orderList.indexOf(10002) > -1) {
              if (res.data.data.dataList.length === 0) {
                this.isHasDownExcel = false
              } else {
                this.isHasDownExcel = true
              }
            }
          } else {
            // this.$message.error(res.data.message)
          }
          const params = {
            loading: false
          }
          this.$store.dispatch('setSelfSearch', params)
          if (isScroll) {
            document.getElementById('srcollBox').scrollIntoView()
          }
        })
    },
    handleItem (type, arrId, arrText) {
      console.log(type, arrId, arrText)
      // 如果没有选择本土本地城市，则显示默认城市
      if (arrId.indexOf(1003) !== -1) {
        if (this.checkLocalBrandCityList.length === 0) {
          for (const item of this.localBrandCityType) {
            if (item.id === this.user.projectProvince) {
              this.checkLocalBrandCityList.push(item.id)
            }
          }
          this.selectLocalBrandCityMoreSure()
        }
      }
      if (type === 'brandTag') {
        this.disabledCityIds = arrId.filter(item => { return item < 1000 })
      }
      const temStr = arrId.join(',')
      const temAaaArr = [1003, 1007]
      let temAaaBol = false
      temAaaArr.map((item) => {
        if (temStr.indexOf(item) !== -1) {
          temAaaBol = true
        }
        return item
      })
      // 区域品牌和未进驻中心 标签不显示
      const aaa = temAaaBol && !this.user.projectName === ''
      if (type !== 'business' && type !== 'cityLine' && !aaa) {
        this.addCheckList(type, arrId, arrText)
      }
      if (type === 'business') {
        // console.log(type, arrId, arrText)
        this.showBrandAttr = true
        this.peoplePriceType = []
        let temBusinessStr = ''
        this.checkBusinessTagList = []
        this.businessType.map((item, key) => {
          item.hint = ''
          return item
        })
        this.checkBusinessList = []
        // 重置人均消费水平价格
        this.peoplePriceType.map((item) => {
          item.active = false
          return item
        })
        this.peoplePriceId = ''
        Bus.$emit('changeCheckList')
        // 一级不限
        if (!temStr) {
          // console.log('一级不限', temStr)
          const temArr = []
          this.conditionHas.map((item) => {
            if (item.type.indexOf('business') === -1 && item.type.indexOf('peoplePrice') === -1 && item.type !== 'busi-three') {
              temArr.unshift(item)
            }
            return item
          })
          this.conditionHas = temArr
          // 一级业态置空
          this.businessId = ''
          // 二级业态置空
          this.noBusinessCondition = true
          this.currentChildId = ''
          // 三级业态置空
          this.businessDetailThree = []
          this.peoplePriceType = []
        } else {
          this.peoplePriceType = changeTexture(this.businessType[this.currentId].price)
          const temSelectArr = []
          for (const iterator of this.conditionHas) {
            if (iterator.type !== 'peoplePrice' && (iterator.type.indexOf('business') === -1)) {
              temSelectArr.push(iterator)
            }
          }
          this.conditionHas = temSelectArr
          // 二级不限  (先注释，之前的一级业态多选逻辑之一)
          this.noBusinessCondition = this.checkBusinessList.length === 0
          let hasTwo = false
          this.businessType.map((item, key) => {
            if (item.hint) {
              temBusinessStr += ',' + item.hint
              // 如果切换到当前一级，且已选中二级，则不加入一级ID
              if (temStr.indexOf(item.id) !== -1) {
                hasTwo = true
              }
            }
            return item
          })
          if (hasTwo) {
            this.businessId = temBusinessStr
            const temArr = []
            this.conditionHas.map((item) => {
              if (item.type !== 'business') {
                temArr.unshift(item)
              }
              return item
            })
            this.conditionHas = temArr
          } else {
            this.businessId = this.businessType[this.currentId].id + ''
            this.addCheckList(type, arrId, [arrText])
          }
        }
        // console.log('businessId', this.businessId)
        // 当无三级业态但有二级特态选中时，业态为二级id
        // 修改选中二级业态 在选其它一级业态时还是之前的二级业态的问题 id 12990
        if (this.currentChildId && this.businessDetailThree.length === 0 && this.businessDetailType.length > 0) {
          this.conditionHas = this.conditionHas.filter(item => { return item.id === this.businessId })
          // console.log('111', this.conditionHas)
        }
        if (this.businessDetailThree && this.businessDetailThree.length > 0) {
          this.conditionHas = this.conditionHas.filter(item => { return item.id === this.businessId })
          // console.log('222', this.conditionHas)
        }
        this.currentChildId = ''
        // console.log(this.conditionHas)
      } else if (type === 'mainland') {
        // console.log(temStr)
        let temBusinessStr = ''
        Bus.$emit('changeCheckList')
        // 一级不限
        if (!temStr) {
          const temArr = []
          this.conditionHas.map((item) => {
            if (item.type.indexOf('mainland') === -1 && item.type !== 'main-three' && item.type !== 'main-two') {
              temArr.unshift(item)
            }
            return item
          })
          this.conditionHas = temArr
          // 一级业态置空
          this.mainlandId = ''
          // 二级业态置空
          this.noMainlandCondition = true
          this.currentMainlandChildId = ''
          // 三级业态置空
          this.mainlandDetailThree = []
          console.log(this.conditionHas)
        } else {
          const temSelectArr = []
          for (const iterator of this.conditionHas) {
            if (iterator.type.indexOf('mainland') === -1) {
              temSelectArr.push(iterator)
            }
          }
          this.conditionHas = temSelectArr
          // 二级不限  (先注释，之前的一级业态多选逻辑之一)
          this.noMainlandCondition = this.checkMainlandList.length === 0
          let hasTwo = false
          this.mainlandType.map((item, key) => {
            if (item.hint) {
              temBusinessStr += ',' + item.hint
              // 如果切换到当前一级，且已选中二级，则不加入一级ID
              if (temStr.indexOf(item.id) !== -1) {
                hasTwo = true
              }
            }
            return item
          })
          if (hasTwo) {
            this.mainlandId = temBusinessStr
            const temArr = []
            this.conditionHas.map((item) => {
              if (item.type !== 'mainland') {
                temArr.unshift(item)
              }
              return item
            })
            this.conditionHas = temArr
          } else {
            this.mainlandId = this.mainlandType[this.currentMainlandId].id
            this.addCheckList(type, arrId, [arrText])
          }
        }
        // console.log('mainlandId', this.mainlandId)
        // 当无三级业态但有二级特态选中时，业态为二级id
        // 修改选中二级业态 在选其它一级业态时还是之前的二级业态的问题 id 12990
        if (this.currentMainlandChildId && this.mainlandDetailThree.length === 0 && this.mainlandDetailType.length > 0) {
          this.conditionHas = this.conditionHas.filter(item => { return item.id === this.mainlandId })
        }
        if (this.mainlandDetailThree && this.mainlandDetailThree.length > 0) {
          this.conditionHas = this.conditionHas.filter(item => { return item.id === this.mainlandId })
        }
        this.currentMainlandChildId = ''
        // console.log(this.conditionHas)
      } else if (type === 'shopNum') {
        this.shopNumId = temStr
      } else if (type === 'brandSpecial') {
        this.brandSpecialId = temStr
      } else if (type === 'area') {
        this.showExpandNeed = true
        this.areaId = temStr
      } else if (type === 'shop') {
        this.shopId = temStr
      } else if (type === 'other') {
        this.otherId = temStr
      } else if (type === 'expand') {
        this.expandId = temStr
      } else if (type === 'brandTag') {
        // 没有绑定项目
        if (aaa) {
          this.resetCheckList(this.brandTagType, parseInt(temStr), (value) => { })
          this.$message({
            type: 'warning',
            message: '请绑定项目后使用功能，绑定请联系客服客服微信号：win18578675071'
          })
          Bus.$emit('changeCheckList')
          return
        } else {
          this.brandTagId = temStr
          if (arrId.length === 0) {
            this.checkLocalBrandCityList = []
          }
          // console.log(this.brandTagId)
        }
      } else if (type === 'projectNumber') {
        this.projectNumberId = temStr
      } else if (type === 'openHobby') {
        this.openHobbyId = temStr
      } else if (type === 'openLine') {
        this.openLineId = temStr
        this.showOpenShop = true
      } else if (type === 'createCity') {
        this.createCityId = temStr
        console.log('conditionHas', this.conditionHas)
        // console.log(type, arrId, arrText)
        if (!arrId.includes(9201)) {
          this.regionBrandCityId = []
          this.conditionHas = this.conditionHas.filter((item) => {
            return !['mainland', 'main-two', 'main-three'].includes(item.type)
          })
          this.noCondition = true
          this.noMainlandCondition = true
          this.currentMainlandChildId = ''
          this.currentMainlandId = ''
          this.mainlandDetailThree = []
          this.noSelectMainland = true
          this.mainlandType = this.mainlandType.map((item) => {
            item.active = false
            return item
          })
          // console.log(this.mainlandType)
        }
      } else if (type === 'cityLine') {
        // this.cityLineId = temStr
        let temCityStr = ''
        // 一级不限
        if (!temStr) {
          this.checkCityLineList = []
          this.shopExpandPlanType.map((item, key) => {
            item.hint = ''
            return item
          })
          const temArr = []
          this.conditionHas.map((item) => {
            if (item.type.indexOf('cityLine') === -1) {
              temArr.unshift(item)
            }
            return item
          })
          this.conditionHas = temArr
          this.provinceId = ''
          this.cityLineId = ''
        } else {
          // 如果存在一级，且已选择对应二级，则将二级选中
          this.checkCityLineList = (this.shopExpandPlanType[this.currentCityId].hint && this.shopExpandPlanType[this.currentCityId].hint.split(',')) || []
          this.checkCityLineList = this.checkCityLineList.map((item) => {
            return item
          })
          // 二级不限
          this.noConditionCity = this.checkCityLineList.length === 0
          let hasTwo = false
          this.shopExpandPlanType.map((item, key) => {
            // console.log(item.hint)
            if (item.hint) {
              item.child.map((items1) => {
                if (item.hint.indexOf(items1.id) !== -1) {
                  temCityStr += ',' + items1.id
                }
                return items1
              })
              // 如果切换到当前一级，且已选中二级，则不加入一级ID
              if (this.currentCityId === key) {
                hasTwo = true
              }
            }
            return item
          })
          if (hasTwo) {
            this.cityLineId = temCityStr
            const temArr = []
            this.conditionHas.map((item) => {
              if (item.type !== 'cityLine') {
                temArr.unshift(item)
              }
              return item
            })
            this.conditionHas = temArr
          } else {
            this.cityLineId = temCityStr
            this.provinceId = temStr
            this.addCheckList(type, arrId, [arrText])
          }
        }
      } else if (type === 'peoplePrice') {
        this.peoplePriceId = temStr
        this.peoplePriceTypeArr = arrId
        const temObj = {
          id: this.businessType[this.currentId].id,
          priceId: temStr
        }
        let hasCurrentType = false
        this.peoplePriceHas.map((item) => {
          if (item.id === this.businessType[this.currentId].id) {
            item.priceId = temStr
            hasCurrentType = true
          }
          return item
        })
        if (!hasCurrentType) {
          this.peoplePriceHas.push(temObj)
        }
      } else if (type === 'selfTag') {
        this.selfTagId = temStr
      } else if (type === 'brandGrade') {
        this.gradesId = temStr
      } else if (type === 'brandClass') {
        this.classId = temStr
      } else if (type === 'cooperationship') {
        this.cooperationshipId = temStr
      } else if (type === 'dataType') {
        this.dataTypeId = temStr
      }
      this.fetchListData()
    },
    // 单选
    selectItem (item, key) {
      this.currentChildKey = key
      this.noBusinessCondition = false
      this.currentChildId = item.id
      // console.log('item, key', item, key)
      this.businessId = item.id + ''
      // 先清除已选一级和三级并添加二级
      let hasTwo = false
      this.conditionHas = this.conditionHas.filter((item) => {
        hasTwo = item.type === 'busi-two'
        return item.type !== 'busi-three' && item.type !== 'business'
      })
      // 时尚精品二级业态获取价格
      if (item.price) {
        this.peoplePriceType = changeTexture(item.price)
        this.peoplePriceId = ''
      } else {
        this.peoplePriceType = []
      }
      if (!hasTwo) {
        this.conditionHas.push({
          id: this.businessDetailType[this.currentChildKey].id,
          text: this.businessDetailType[this.currentChildKey].item + '-' + this.businessType[this.currentId].item,
          type: 'busi-two'
        })
      } else {
        for (const iterator of this.conditionHas) {
          if (iterator.type === 'busi-two') {
            iterator.text = item.item + '-' + this.businessType[this.currentId].item
            iterator.id = item.id
            iterator.type = 'busi-two'
          }
        }
      }
      // console.log('selectItem', item, key)
      this.fetchListData()
    },
    // 无限
    selectNoCondition () {
      this.noBusinessCondition = true
      this.currentChildId = ''
      this.businessId = this.businessType[this.currentId].id + ''
      this.fetchListData()
    },
    // 本土品牌二级筛选
    selectItemMainland (item, key) {
      this.currentMainlandChildKey = key
      this.noMainlandCondition = false
      this.currentMainlandChildId = item.id
      let hasTwo = false
      // console.log(this.conditionHas)
      this.conditionHas = this.conditionHas.filter((item) => {
        hasTwo = item.type === 'main-two'
        return item.type !== 'main-three' && item.type !== 'mainland'
      })
      if (!hasTwo) {
        this.conditionHas.push({
          id: this.mainlandDetailType[this.currentMainlandChildKey].id,
          text: this.mainlandDetailType[this.currentMainlandChildKey].item + '-' + this.mainlandType[this.currentMainlandId].item,
          type: 'main-two'
        })
      } else {
        for (const iterator of this.conditionHas) {
          if (iterator.type === 'main-two') {
            iterator.text = item.item + '-' + this.mainlandType[this.currentMainlandId].item
            iterator.id = item.id
            iterator.type = 'main-two'
          }
        }
      }
      // console.log('selectItemMainland', item, key)
      this.fetchListData()
    },
    selectBrand (brand) {
      this.brandKeyWordListShow = false
      this.brandKeyWord = brand.brandName
      this.brandId = brand.brandId
      this.brandLoad = true
      this.isOne = false
      this.fetchListData(true)
    },
    fetchBrand () {
      this.brandId = ''
      let brandKeyWord = ''
      this.brandLoad = true
      if (this.brandKeyWord !== '') {
        brandKeyWord = this.brandKeyWord
      }
      if (brandKeyWord) {
        this.brandKeyWordListShow = true
        const params = {
          count: encodeURI(encodeURI(this.brandKeyWord))
        }
        this.axios.get(api.libraryBrandByKeyworld, { params: params })
          .then((res) => {
            this.brandLoad = false
            if (res.data.code === 0) {
              this.brandKeyWordList = changeTexture(res.data.data)
            } else if (res.data.code === 0) {
              this.brandKeyWordList = []
            }
            if (!this.isOne) {
              this.brandKeyWordList = []
              this.brandKeyWordListShow = false
            }
          })
      } else {
        this.brandKeyWordListShow = false
      }
    },
    keyWordInput () {
      this.isOne = true
    },
    selectOrder (version, id, item) {
      if (id < 9100) {
        if (id < 9006) {
          this.orderActive = false
          this.orderItem = '品牌指数排序'
        } else {
          this.orderActive = true
        }
      }
      if (item) {
        this.orderItem = item
        this.sortShow = false
      }
      if (id === 9005 || id === 9102) {
        this.isSelectFollow = true
      } else {
        this.isSelectFollow = false
        this.isFollow = false
      }
      if (id === 9004 && this.isMatched === 0) {
        if (this.matchType === 2) {
          const mockHref = this.$router.resolve({
            path: '/MockRoute',
            query: {
              source: 2
            }
          })
          window.open('/' + mockHref.href, '_blank')
        } else {
          this.$confirm('请输入已签约品牌获取品牌得分后使用该功能', '温馨提示', {
            cancelButtonText: '暂时不用',
            confirmButtonText: '立即使用',
            center: true,
            customClass: 'order-message'
          }).then(() => {
            if (this.isMatched === 0) {
              this.isMatch = true
            } else {
              const mockHref = this.$router.resolve({
                path: '/MockRoute',
                query: {
                  source: 2
                }
              })
              window.open('/' + mockHref.href, '_blank')
            }
          })
        }
      } else {
        if (id < 9100) {
          this.$emit('update:orderType', id)
          this.orderHasChange = true
          this.currentOrderType = id
        } else {
          this.$emit('update:menuFilterId', id)
          this.menuFilter = id
        }
        this.fetchListData()
        this.orderSingle.map((item) => {
          if (item.id === id) {
            item.active = true
          } else {
            item.active = false
          }
          return item
        })
        if (id >= 9100) {
          this.orderFilter.map((item) => {
            if (item.id === id) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
        } else {
          this.orderSingle2.map((item) => {
            if (item.id === id) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
        }
      }
    },
    handleChange (val) {
      this.radio_type = val[0]
      this.regionType = val[1] || ''
      this.fetchListData()
    },
    selectSortClick (id, item) {
      this.currentOrderType = id
      this.selectOrderItem = item
      this.$emit('update:orderType', id)
      this.fetchListData()
    },
    changeOrder () {
      // console.log('changeOrder', this.orderList)
      if (this.orderList.indexOf(8001) > -1) {
        this.otherId = 8001
      } else {
        this.otherId = ''
      }
      if (this.orderList.indexOf(10003) > -1) {
        this.isLookBrand = 1
      } else {
        this.isLookBrand = 0
      }
      this.fetchListData()
    },
    initData () {
      this.axios.get(api.selfSearch)
        .then((res) => {
          if (res.data.code === 0) {
            // 业务类型
            this.businessType = changeTexture(res.data.data.attribute.categoryFormat)
            // 本土品牌
            this.mainlandType = changeTexture(res.data.data.regionBrand)
            // 单选排序
            this.orderSingle = changeTexture(res.data.data.sort_way)
            this.orderOptions = res.data.data.sort_way2.map((item) => {
              return {
                value: item.id,
                label: item.item,
                children: item.child && item.child.map((item2) => {
                  return {
                    value: item2.id,
                    label: item2.item
                  }
                })
              }
            })
            // console.log('this.$route.query.orderId', this.$route.query.orderId)
            this.orderFilter = changeTexture(res.data.data.menuFilter)
            // 品牌指数
            this.orderIndex = changeTexture(res.data.data.sort_way_index)
            // 高亮默认业态
            const temArr = this.currentBusinessType ? this.currentBusinessType.split('-') : []
            if (temArr.length > 0) {
              if (this.$route.query.orderId) {
                if (this.$route.query.orderId === '9006' || this.$route.query.orderId === '9010') {
                  this.currentOrderType = [parseInt(this.$route.query.orderId), 1]
                } else {
                  this.currentOrderType = [parseInt(this.$route.query.orderId)]
                }
              }
              // this.orderSingle[3].active = true
              this.noSelect = false
              this.businessType = this.businessType.map((item, index) => {
                if (item.id === temArr[0]) {
                  this.businessId = item.id + ''
                  this.currentId = index
                  item.active = true
                  if (temArr.length >= 2) {
                    item.child = item.child.map((item2, index2) => {
                      if (item2.id === temArr[1]) {
                        this.businessId = item2.id + ''
                        this.currentChildKey = index2
                        item2.active = true
                        this.currentChildId = item2.id
                        if (temArr.length === 3) {
                          item2.child = item2.child.map((item3) => {
                            if (item3.id === temArr[2]) {
                              this.businessId = item3.id + ''
                              this.noBusinessCondition = false
                              setTimeout(() => {
                                this.businessDetailThree = [item3.id]
                              }, 0)
                              item3.active = true
                            }
                            return item3
                          })
                        }
                      }
                      return item2
                    })
                  }
                }
                return item
              })
            } else {
              this.orderSingle[0].active = true
              // this.orderSingle2[0].active = true
              this.orderFilter[0].active = true
              this.orderActive = false
            }
            // 面积要求
            this.areaType = changeTexture(res.data.data.demand.demandArea)
            // 拓展区域
            // this.expandType = changeTexture(res.data.data.regions, {id: 'regionID', item: 'regionName'})
            // 开店方式
            this.shopType = changeTexture(res.data.data.attribute.openMode, { item: 'value' })
            // 开店数量
            this.projectNumberType = changeTexture(res.data.data.opening.shopNum)
            // 开店城市线级
            // 2019/03/18 修改去掉城市线级 读取开店城市
            this.openLineType = changeTexture(res.data.data.opening.cityList)
            // this.openLineType = changeTexture(res.data.data.opening.cityLevel)
            // 楼层偏好
            this.openHobbyType = changeTexture(res.data.data.opening.floorPreference)
            // 楼层偏好
            this.otherType = changeTexture(res.data.data.other)
            // 计划开店数量
            this.shopNumType = changeTexture(res.data.data.demand.planShopNum)
            // 计划拓展城市
            this.shopExpandPlanType = changeTexture(res.data.data.demand.planExpandCity)
            // 创立地
            this.createCityType = changeTexture(res.data.data.attribute.founded)
            // 品牌分类
            this.brandTagType = changeTexture(res.data.data.brandLabel)
            // this.disabledCityIds = this.brandTagType.filter((item) => {
            //   return item.id < 1000
            // }).map((item) => { return item.id })
            if (this.$route.query.from === 'NewPower') {
              this.brandTagType = this.brandTagType.map((item) => {
                if (item.id === 1016) {
                  item.active = true
                  this.conditionHas.push({ id: 1016, text: '星势力品牌', type: 'brandTag' })
                } else {
                  item.active = false
                }
                return item
              })
              this.brandTagId = '1016'
            }
            if (this.$route.query.from === 'NewBrand') {
              this.brandTagType = this.brandTagType.map((item) => {
                if (item.id === 1001) {
                  item.active = true
                  this.conditionHas.push({ id: 1001, text: '新兴品牌', type: 'brandTag' })
                } else {
                  item.active = false
                }
                return item
              })
              this.brandTagId = '1001'
            }
            if (this.$route.query.from === 'LeadingBrand') {
              this.brandTagType = this.brandTagType.map((item) => {
                if (item.id === 1014) {
                  item.active = true
                  this.conditionHas.push({ id: 1014, text: '人气品牌', type: 'brandTag' })
                } else {
                  item.active = false
                }
                return item
              })
              this.brandTagId = '1014'
            }
            if (this.$route.query.from === 'PotentialBrand') {
              this.brandTagType = this.brandTagType.map((item) => {
                if (item.id === 1002) {
                  item.active = true
                  this.conditionHas.push({ id: 1002, text: '潜力品牌', type: 'brandTag' })
                } else {
                  item.active = false
                }
                return item
              })
              this.brandTagId = '1002'
            }
            // 品牌档次
            this.brandGradeType = changeTexture(res.data.data.brandGrade)
            // console.log('this.brandGradeType', this.brandGradeType)
            // 品牌级次
            this.brandClassType = changeTexture(res.data.data.brandClass)
            // 品牌关系
            this.cooperationshipType = changeTexture(res.data.data.cooperationship)
            // 数据类型
            // this.dataType = changeTexture(res.data.data.dataType)
            // if (this.$route.query.from === 'self') {
            //   this.dataType = this.dataType.map((item) => {
            //     if (item.id === 10011) {
            //       item.active = true
            //       this.conditionHas.push({ id: this.dataTypeId, text: item.item, type: 'dataType' })
            //     } else {
            //       item.active = false
            //     }
            //     return item
            //   })
            //   this.dataTypeId = 10011
            // }
            // 区域品牌城市
            for (const iterator of res.data.data.brandLabel) {
              if (iterator.id === 1003) {
                this.localBrandCityType = changeTexture(iterator.child)
                // 显示默认城市
                for (const item of this.localBrandCityType) {
                  if (item.id === this.user.province_id) {
                    this.checkLocalBrandCityList.push(item.id)
                  }
                }
              }
            }
            // 特色品牌
            this.brandSpecialType = changeTexture(res.data.data.operation_feature)
            // console.log('this.brandSpecialType', this.brandSpecialType)
            // 自定义标签
            const selfTagArr = []
            for (let i = 0; i < res.data.data.attribute.customLabel.length; i++) {
              if (res.data.data.attribute.customLabel[i].replace(/\s+/g, '') !== '') {
                selfTagArr.push(res.data.data.attribute.customLabel[i])
              }
            }
            this.selfTagType = selfTagArr.map((item) => {
              const temObj = {
                child: '',
                hint: '',
                id: item,
                item: item,
                price: '',
                active: false
              }
              return temObj
            })
            // 是否有匹配分数
            this.isMatched = res.data.data.isMatched
            // 是否有深度匹配
            this.matchType = res.data.data.matchType
            this.$emit('matchTypeFunc', res.data.data.matchType)
          } else {
            // this.$message.error(res.data.message)
          }
          this.searchLoading = false
          this.againAllList()
        })
    },
    resetCheckList (type, id, callback) {
      const temArr = []
      type.map((item) => {
        // console.log('2222', item.id, id)
        if (item.id === id) {
          item.active = false
        }
        if (item.active) {
          temArr.push(item.id)
        }
        return item
      })
      callback(temArr.join(','))
    },
    showState () {
      this.businessStateShow = !this.businessStateShow
    },
    selectState (state) {
      this.businessState = state
      this.businessStateShow = false
    },
    toDetail (id) {
      const params = {
        id: id
      }
      this.$router.push({
        path: '/MockBusinessDetail',
        query: params
      })
    },
    // 跳去addbrand
    addBrandClick () {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
      }
    },
    showFollow () {
      this.isFollow = !this.isFollow
    },
    hideFollow () {
      this.isFollow = !this.isFollow
    },
    selectFollow (item) {
      this.userName = item.userName
      this.isFollow = !this.isFollow
      this.addUid = item.userid
      this.fetchListData()
    },
    contactTypeHandle () {
      if (this.contactType) {
        this.otherId = '8001'
      } else {
        this.otherId = ''
      }
      this.fetchListData()
    },
    // 开店城市筛选方法
    openCityUnlimited () {
      this.noOpenCheckedSel = true
      this.openCityIdx = null
      this.openLineId = ''
      this.checkOpenCityList = []
      this.addCheckList('openLine', [], [])
      this.fetchListData()
    },
    openCityFunc (item) {
      // console.log(item)
      this.noOpenCheckedSel = false
      this.openCityIdx = item.id
      this.openCityDetailList = item.child
      if (this.openCityIdx === 301 || this.openCityIdx === 309 || this.openCityIdx === 302 || this.openCityIdx === 322) {
        this.checkOpenCityList.push(this.openCityDetailList[0].id)
        this.selectOpenCityLabel.push(this.openCityDetailList[0].item)
        this.checkOpenCityList = [...new Set(this.checkOpenCityList)]
        this.selectOpenCityLabel = [...new Set(this.selectOpenCityLabel)]
        this.addCheckList('openLine', this.checkOpenCityList, this.selectOpenCityLabel)
        this.openLineId = this.checkOpenCityList.join(',')
        this.fetchListData()
      }
    },
    selsectOpenCityDetails (item) {
      this.openLineId = this.checkOpenCityList.join(',')
      // this.selectOpenCityLabel = []
      for (let i = 0; i < this.openCityDetailList.length; i++) {
        if (this.checkOpenCityList[this.checkOpenCityList.length - 1] === this.openCityDetailList[i].id) {
          this.selectOpenCityLabel.push(this.openCityDetailList[i].item)
        }
        // for (let j = 0; j < this.checkOpenCityList.length; j++) {
        //   if (this.checkOpenCityList[j] === this.openCityDetailList[i].id) {
        //     this.selectOpenCityLabel.push(this.openCityDetailList[i].item)
        //   }
        // }
      }
      if (this.checkOpenCityList.length !== this.selectOpenCityLabel.length) {
        this.selectOpenCityLabel = [...new Set(this.selectOpenCityLabel)]
        this.selectOpenCityLabel.pop()
      }
      this.addCheckList('openLine', this.checkOpenCityList, this.selectOpenCityLabel)
      this.fetchListData()
    }
  }
}
</script>

<style lang="stylus" scoped>
.condition-wrap
  background-color #272930
  color #999999
  margin-bottom 16px
.order-wrap
  position relative
  height 40px
  margin-top -0.5px
  line-height 38px
  background-color #272930
  &>span
    display inline-block
    padding-left 14px
    padding-right 14px
    color #999999
    font-size 14px
    border-right 1px solid #383e46
    box-sizing border-box
    cursor pointer
  .order-active
    height 39px
    color #ffffff
    background-color #3a3c42
    .icon-order-down:after
      color #fff
.order-checkbox
  display inline-block
  padding-left 20px
.rand-search
  position relative
  margin-bottom 24px
  height 40px
  line-height 40px
  font-size 14px
  .select-list
    position absolute
    top 41px
    left 0
    width 85.5%
    max-height 340px
    padding-top 1px
    z-index 11111
    overflow-y auto
    overflow-x hidden
    background #272930
    li
      text-align left
      padding-left 20px
      color #fff
      &:hover
        background-color #626871
        color #fff
  .scroller-bar
    overflow-y scroll
  input
    float left
    width 985px
    background-color #272930
    border 1px solid #272930
    height 100%
    box-sizing border-box
    color #e5e5e5
    text-align left
    padding-left 20px
  span
    position relative
    float left
    display inline-block
    background-color #3E3E45
    width 110px
    color #F4F4F4
    text-indent 50px
    cursor pointer
    font-size 14px
    overflow hidden
    &:before
      position absolute
      left -24px
      font-family 'iconfont'
      font-size 22px
      -webkit-font-smoothing antialiased
      -webkit-text-stroke-width 0.2px
      display inline-block
      color #f4f4f4
      content '\e741'
.condition-item-detail
  position relative
  background-color #191d23
  margin-left 140px
  margin-bottom 14px
  padding 5px
  font-size 12px
  .condition-b-l
    position absolute
    top 0
    left -13px
    height 89px
    width 1px
  .condition-item
    display inline-block
    margin-left 15px
    margin-right 15px
    padding-top 2px
    padding-bottom 4px
    cursor pointer
    box-sizing border-box
    margin-top 6px
    margin-bottom 6px
    border-bottom 2px solid rgb(68, 75, 87)
    &:hover
      padding-top 2px
      padding-bottom 4px
      border-bottom 2px solid #FFA134
      color #ffffff
      box-sizing border-box
  .condition-active
    padding-top 2px
    padding-bottom 2px
    border-bottom 2px solid #FFA134
    color #ffffff
    box-sizing border-box
.condition-tit
  position relative
  padding-right 12px
  padding-left 15px
  height 36px
  line-height 36px
  font-size 15px
  color rgba(255, 255, 255, 1)
  cursor pointer
  font-weight 600
  &:after
    position absolute
    left 0
    top 0
    height 36px
    width 223px
    background -moz-linear-linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 161, 52, 0))
    background -webkit-linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 161, 52, 0))
    background -o-linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 161, 52, 0))
    background -ms-linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 161, 52, 0))
    background linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 161, 52, 0))
    background-size 223px
    background-repeat no-repeat
    z-index 111
.condition-left-text
  position relative
  z-index 112
.condition-content
  padding-right 12px
  padding-left 15px
.condition-right-text
  position relative
  padding-right 20px
  font-weight 400
  z-index 1111
.condition-has
  background-color #484A50
  padding-top 10px
  .condition-has-box
    width 1280px
    margin 0 auto
.condition-has-tit
  padding-left 12px
  padding-top 6px
  padding-right 58px
  font-size 14px
  color #d8d8d8
.condition-has-item
  display inline-block
  position relative
  padding 3px 24px 3px 8px
  margin-right 16px
  margin-bottom 13px
  margin-top 3px
  border 1px solid #FFA134
  color #FFA134
  font-size 12px
  cursor pointer
  &:after
    position absolute
    top 3px
    right 4px
    content '\e732'
    font-family 'iconfont'
    font-size 16px
    // line-height 150%
    font-style normal
    -webkit-font-smoothing antialiased
    -webkit-text-stroke-width 0.2px
    display inline-block
    color #FFA134
  &:hover
    color #fb9218
    border 1px solid #fb9218
.condition-reset
  display inline-block
  color rgba(255, 161, 52, 0.5)
  font-size 14px
  vertical-align middle
  cursor pointer
.condition-has-con
  width 1130px
.icon-arrow-down:after
  font-size 12px
  color #FFA134
.arrow-active
  &:after
    transform translateZ(0)
    transform translateY(-50%) rotate(180deg)
.condition-detail-wrap
  ponsition relative
  border-bottom 1px solid rgba(226, 226, 226, 0.1)
.conditon-sj
  position absolute
  top -5px
  left 280px
  display inline-block
  width 18px
  height 18px
  background-color #191d23
  transform rotate(-45deg)
.add-brand-btn
  font-style normal
  display inline-block
  width 152px
  height 38px
  line-height 36px
  border 1px solid #FFA134
  border-radius 4px
  margin-left 25px
  text-align center
  color #FFA134
  cursor pointer
  i
    font-style normal
    font-size 18px
    margin-right 10px
.message-wrap
  background-color rgba(255, 255, 255, 0.1)
  margin-top 10px
  padding 8px
  p
    color #fff
    font-size 14px
    span
      color #ffa134
      cursor pointer
    span.brandNum
      cursor auto
  p.second
    color rgba(255, 255, 255, 0.5)
    margin-top 5px
    margin-left 16px
  .icon-msg:before
    color #fff
    font-size 20px
</style>
<style lang="stylus">
.el-message-box
  width 630px
.mock-back-fix-wrap
  width 100%
  height 50px
  position fixed
  top 0px
  left 0
  z-index 111
  background #272930
  margin-top 0
  // border-bottom 1px solid rgba(255,255,255,.3)
  // box-shadow 0px 11px 35px 0px rgba(30,30,35,1)
  .order-wrap
    margin-top 0px
.mock-back-fix
  position fixed
  top 0
  left 0
  width 100%
  z-index 11111
  margin-top 0
  .order-wrap
    max-width 1280px
    margin auto
    &>span
      line-height 50px !important
      padding-left 25px !important
      padding-right 25px !important
  .order-active
    height 50px !important
    padding-bottom 2px
.msg-notice
  height 40px
  background-color #ffffff
  margin-bottom 20px
  position relative
  line-height 40px
  padding-left 30px
  font-size 14px
.msg-left
  position absolute
  height 40px
  line-height 40px
.msg-right
  display inline-block
  margin-left 150px
  width 700px
  overflow hidden
  height 40px
  line-height 43px
  ul
    display inline-block
    width 2800px
    height 40px
    li
      width 750px
      overflow hidden
      float left
      span
        margin-left 10px
        color rgba(255, 161, 52, 1)
        cursor pointer
.cityspan
  position absolute
  left 0px
.CityList
  color #fff
  padding-top 15px
  line-height 22px
  font-size 14px
  background-color #272930
  padding-left 10px
  padding-right 10px
  padding-bottom 10px
  // border-top 1px solid rgba(255, 255, 255, 0.1)
  position relative
  margin-top 16px
  .el-icon-warning
    position absolute
    left 15px
    top 19px
  &>div
    padding-left 23px
.sort-wrap
  position relative
  .el-icon-information
    margin-right 5px
    &:before
      color rgba(255, 255, 255, 0.3)
  .el-icon-arrow-down
    margin-top 5px
    &:before
      font-size 12px
      color rgba(255, 255, 255, 1)
  .el-icon-arrow-up
    margin-top 5px
    &:before
      font-size 12px
      color rgba(255, 255, 255, 1)
.sort-list
  position absolute
  left -5px
  top 40px
  width 170px
  background #272930
  border 1px solid rgba(255, 255, 255, 0.4)
  border-radius 2px
  z-index 1111
  li
    font-size 14px
    padding-left 10px
    color #fff
    &:hover
      background #ffa134
.brandIndex-tips
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  z-index 11111
  .tips-box
    position fixed
    top 20%
    left 50%
    margin-left -325px
    width 650px
    padding 20px 32px 40px 32px
    background #fff
    z-index 11111
    h3
      text-align center
      color #333
      font-size 28px
    .content
      margin-top 25px
      .title
        color #333
        font-size 14px
        margin-top 6px
        margin-bottom 6px
      .text
        color #666
        font-size 14px
        line-height 22px
    .el-icon-close
      cursor pointer
.down-brand-exel
  float right
  &>span
    display inline-block
    padding-left 10px
    padding-right 10px
    color #999999
    font-size 14px
    box-sizing border-box
    i
      color #FFA134 !important
  .down-btn
    width 114px
    text-align center
    color #FFA134
    border 1px solid #FFA134
    height 22px
    line-height 21px
    padding 0
    border-radius 2px
    margin-right 10px
    cursor pointer
    font-size 12px
.open-city-style
  .condition-block
    font-size 14px
    overflow hidden
    vertical-align middle
  .condition-checkbox
    label
      margin-top 18px
      margin-bottom 1px
  .condition-checkbox
    span
      margin-top 18px
      margin-bottom 1px
  .condition-tit1
    display inline-block
    box-sizing border-box
    width 120px
    padding-right 8px
    color #bebebe
  .condition-right
    width 1130px
    padding-bottom 15px
  .condition-item
    display inline-block
    margin-left 13px
    margin-right 10px
    padding-bottom 2px
    border-bottom 2px solid #272930
    cursor pointer
    color #bebebe
    &:hover
      border-bottom 2px solid #FFA134
      color #ffffff
      box-sizing border-box
  .condition-active
    border-bottom 2px solid #FFA134
    color #ffffff
    box-sizing border-box
  .condition-item-detail
    position relative
    background-color #191d23
    margin-left 140px
    margin-bottom 14px
    padding 5px
    font-size 12px
.five-version
  .el-icon-information
    margin 0 5px
  .order-wrap
    height 48px
    line-height 47px
    display flex
    width 100%
    .five-version-text
      position relative
      color #fff
      .order-ul
        position absolute
        left 0
        top 40px
        z-index 1111
        width 132px
        text-align center
        background-color #272930
        color #fff
        box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
        border-radius 3px
        li
          height 40px
          line-height 40px
          &:hover
            background-color rgba(246, 247, 250, 0.1)
            color #fff
          &.active
            color #FFA134
    .down-brand-exel
      margin-left auto
      .down-btn
        background-color #FCA000
        color #fff
        height 28px
        line-height 28px
        border none
</style>
