<template>
  <div :class="{'conditon-border': !noNeedbottomBorder}">
    <div
      class="condition-block condition-checkbox clearfix"
      v-show="!selectMoreState"
    >
      <span
        class="fl condition-tit"
        v-if="title"
      >{{title}}</span>
      <div class="fl condition-right ">
        <span
          class="condition-item"
          :class="{'condition-active': noCondition}"
          v-if="needMore"
          style="margin-left: 14px;"
          @click="selectNoCondition"
        >不限</span>
        <span
          class="condition-item"
          :key="key"
          v-for="(item, key) in list"
          @click="selectItem(item.id, key)"
          :class="{'condition-active': item.active}"
        >{{item.item}}{{item.hint && '(' + item.hint.split(',').length + ')'}}</span>
      </div>
      <!-- <span class="condition-btn" @click="selectMore" v-if="canSelectAll">多选</span> -->
    </div>
    <div
      class="condition-block condition-checkbox clearfix"
      v-show="selectMoreState"
      v-if="canSelectAll"
    >
      <span
        class="fl condition-tit"
        v-if="title"
      >
        {{title}}
        <el-tooltip
          class="item"
          effect="dark"
          :content="question"
          v-if="question"
          placement="right-end"
        >
          <i class="icon-question"></i>
        </el-tooltip>
      </span>
      <div class="fl condition-right">
        <span
          class="fl condition-item"
          style="margin-left: 14px;"
          :class="{'condition-active': noCondition}"
          v-if="selectShowMore"
          @click="selectMoreNoCondition"
        >不限</span>
        <el-checkbox-group
          v-if="!needShowTip"
          class="fl"
          style="width: 1070px"
          v-model="checkList"
          @change="selectMoreSure"
        >
          <el-checkbox
            :label="item.id"
            :key="key"
            v-for="(item, key) in list"
          >{{item.item}}</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group
          v-if="needShowTip"
          class="fl"
          style="width: 1070px"
          v-model="checkList"
          @change="selectMoreSure"
        >
          <el-checkbox
            :label="item.id"
            :key="key"
            v-for="(item, key) in list"
            :disabled="disabledCityIds.includes(item.id)"
            :class="[item.item === '逆势增长品牌' ? 'colour': '']"
          >
            <span class="new-label"></span>
            <el-tooltip
              effect="dark"
              v-if="item.hint"
            >
              <div slot="content">
                {{item.hint}}
              </div>
              <span>{{item.item}}</span>
            </el-tooltip>
            <span v-else>
              <span>{{item.item}}</span>
            </span>
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '../../utils/bus.js'
export default {
  name: 'SearchItem',
  data () {
    return {
      checkList: []
    }
  },
  // watch: {
  //   list: function (val, old) {
  //     for (var items of this.list) {
  //       if (items.active) {
  //         this.noCondition = false
  //       }
  //     }
  //   }
  // },
  props: {
    noCondition: {
      type: Boolean,
      default: true
    },
    needShowTip: {
      type: Boolean,
      default: false
    },
    noNeedbottomBorder: {
      type: Boolean,
      default: false
    },
    needMore: {
      type: Boolean,
      default: true
    },
    selectShowMore: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    list: {
      type: Array,
      required: true
    },
    canSelectAll: {
      type: Boolean,
      default: true
    },
    selectMoreState: {
      type: Boolean,
      default: false
    },
    noSelect: {
      type: Boolean,
      default: true
    },
    needCurrentId: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    disabledCityIds: {
      type: Array,
      default: () => []
    },
    question: {
      type: String,
      default: ''
    }
  },
  mounted () {
    setTimeout(() => {
      this.selectMore()
    }, 1000)
    // clearTimeout(st)
    // 这里有坑，不能这么搞，后面优化
    Bus.$on('reflash', () => {
      // this.noCondition = true
      this.$emit('update:noCondition', true)
    })
    Bus.$on('changeCheckList', () => {
      this.selectMore()
    })
  },
  methods: {
    selectMoreNoCondition () {
      // this.noCondition = true
      const temArr = []
      this.list.map((item) => {
        item.active = false
        return item
      })
      this.checkList = []
      // this.selectMoreState = false
      this.$emit('handleItem', this.type, temArr)
      this.$emit('update:list', this.list)
      this.$emit('update:noCondition', true)
    },
    // 多选
    selectMore () {
      // this.selectMoreState = true
      const temArr = []
      this.list.map((item) => {
        if (this.checkList.length === 0 && item.active) {
          temArr.push(item.id)
        } else {
          this.checkList.map((items) => {
            if ((items === item.id) && item.active) {
              temArr.push(items)
            }
            return items
          })
        }
        return item
      })
      this.checkList = temArr
    },
    // 单选
    selectItem (id, key) {
      if (this.showFirstMask) {
        return
      }
      // this.noCondition = false
      let arrText = ''
      this.list.map((item) => {
        item.active = false
        if (item.id === id) {
          item.active = !item.active
          arrText = item.item
        }
        return item
      })
      this.checkList = []
      if (this.needCurrentId) {
        this.$emit('update:currentId', key)
      }
      this.$emit('handleItem', this.type, [id], arrText)
      this.$emit('update:list', this.list)
      this.$emit('update:noCondition', false)
      this.$emit('update:noSelect', false)
    },
    // 确定多选
    selectMoreSure () {
      // if (this.checkList.length === 0) {
      //   this.$message({
      //     message: '您尚未选择' + this.title + '哦',
      //     type: 'warning'
      //   })
      //   return
      // }
      // this.noCondition = false
      // 360 7.1需要加定时器才可以正确选择，我也不知道为啥...

      const st = setTimeout(() => {
        const temArr = []
        const arrText = []
        this.list.map((item) => {
          item.active = false
          this.checkList.map((selected) => {
            if (item.id === selected) {
              item.active = true
              temArr.push(item.id)
              arrText.push(item.item)
            }
            return selected
          })
          return item
        })
        clearTimeout(st)
        // this.selectMoreState = false
        this.$emit('handleItem', this.type, temArr, arrText)
        this.$emit('update:list', this.list)
        this.$emit('update:noCondition', false)
        this.$emit('update:noSelect', false)
      }, 0)
    },
    // 取消选择
    selectMoreCancel () {
      this.selectMoreState = false
    },
    // 无限
    selectNoCondition () {
      if (this.showFirstMask) {
        return
      }
      // this.noCondition = true
      this.list.map((item) => {
        item.active = false
        return item
      })
      this.$emit('handleItem', this.type, [''], '')
      this.$emit('update:list', this.list)
      this.$emit('update:noSelect', true)
      this.$emit('update:noCondition', true)
    }
  }
}
</script>

<style lang="stylus" scoped>
.conditon-border
  border-bottom 1px solid rgba(226, 226, 226, 0.1)
  &:last-child
    border none !important
.contentBorder .conditon-border:nth-child(3)
  border none !important
.borderNone .conditon-border:nth-child(1)
  border none !important
.condition-block
  font-size 14px
  overflow hidden
  vertical-align middle
  .el-checkbox-group
    margin-left 0
.condition-tit
  display inline-block
  box-sizing border-box
  width 120px
  padding-right 8px
  color #bebebe
.condition-item
  display inline-block
  margin-left 13px
  margin-right 10px
  padding-bottom 2px
  border-bottom 2px solid #272930
  cursor pointer
  color #bebebe
  &:hover
    padding-bottom 2px
    border-bottom 2px solid #ffa134
    color #ffffff
.condition-checkbox
  label, span
    margin-top 18px
    margin-bottom 1px
  .condition-btn
    padding-top 6px
    margin-bottom 4px
  label
    margin-left 10px
    margin-right 10px
.condition-active
  padding-bottom 2px
  border-bottom 2px solid #ffa134
  color rgba(255, 255, 255, 1)
.condition-btn
  float right
  width 60px
  margin-top 4px
  margin-bottom 4px
  padding-top 6px
  padding-bottom 6px
  margin-right 14px
  background-color #3b424b
  color #b2b2b2
  border 1px solid #58606e
  text-align center
  font-size 12px
  cursor pointer
.condition-right
  width 1130px
  padding-bottom 15px
>>> .el-checkbox__input.is-checked+.el-checkbox__label
  color #bebebe
>>> .el-checkbox__input.is-checked .el-checkbox__inner
  background-color #ffa134
  border-color #ffa134
</style>
