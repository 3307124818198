<template>
  <li
    class="list-item list-item-self"
    :class="[detail.check ? 'select-down-active-li': '']"
  >
    <!-- <el-tooltip placement="top" effect="light">
      <span v-if="user.version === '1.0'" class="unchecked-img" :class="[check ? 'select-down-active': '']" @click="selectDownBrand(index, detail.brandId)"></span>
      <div slot="content">
        {{check ? '您已选择该品牌': '点击选择'}}
      </div>
    </el-tooltip> -->
    <!-- <el-tooltip placement="top" effect="light"> -->
    <img v-if="detail.isGrowth == 1" class="addpic" src="@/assets/images/addpic.png" />
    <span
      :title="detail.check ? '您已选择该品牌': '点击选择'"
      class="unchecked-img"
      :class="[detail.check ? 'select-down-active': '']"
      @click="selectDownBrand(detail.brandId)"
    ></span>
    <!-- <div slot="content">
      {{check ? '您已选择该品牌': '点击选择'}}
    </div> -->
    <!-- </el-tooltip> -->
    <!-- <span
      class="star-tag"
      v-if="detail.trend === 1"
    ></span> -->
    <span
      class="update"
      v-if="detail.update7 === 1"
    >7天内更新</span>
    <div class="item-info-xx">
    </div>
    <div class="item-wrap">
      <span
        class="item-collect"
        v-if="type !== 'selfBusiness' && detail.isAttention === 1"
      >已关注</span>
      <span
        class="item-image"
        :style="{'background-image': 'url(' + brandLogo + ')', 'z-index': (index === 0 && tipStep === 2) ? '111' : '0'}"
        @click="toDetail(detail.brandId, detail.questionState, detail.gbid)"
      >
      </span>
      <div class="item-info">
        <span
          :title="detail.brandName + detail.brandEnglishName"
          class="item-info-tit"
          @click="toDetail(detail.brandId, detail.questionState, detail.gbid)"
        >{{brandName}}</span>
      </div>
      <div>
        <p style="font-size: 12px;">{{detail.typeName}}/{{areaNeed}}</p>
        <p
          class="ellipsis"
          v-if="detail.shopNum"
        >{{detail.shopNum}}{{detail.shopNum && detail.chainMarketNum ? '+': ''}}{{detail.chainMarketNum>0?detail.chainMarketNum + '家百货':''}}</p>
        <p
          class="ellipsis"
          v-else
        >{{detail.shopNum}}{{detail.shopNum && detail.chainMarketNum ? '+': ''}}{{detail.chainMarketNum>0?'已进驻' + detail.chainMarketNum + '家百货':''}}</p>
      </div>
    </div>
    <template>
      <div
        v-if="tagname.length > 1"
        class="brand-label-list"
      >
        <span>{{tagname}}</span>
      </div>
    </template>
  </li>
</template>

<script>
import api from '../../api'
import { mapGetters } from 'vuex'
import { checkParams, setCookie, getCookie } from '../../utils'
import Bus from '../../utils/bus.js'
export default {
  name: 'SearchItem',
  data () {
    return {
      businessState: 0,
      colors: ['#ffa134', '#ffa134', '#ffa134'],
      changeState: false,
      state: 0,
      currentState: false,
      stateTip: '',
      brandIndex: '',
      allSelect: false
    }
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    orderType: {
      type: Number
    },
    type: {
      type: String,
      default: ''
    },
    currentId: {
      type: Number
    },
    index: {
      type: Number
    },
    tipStep: {
      type: Number
    },
    showFirstMask: {
      type: Boolean
    },
    matchType: {
      type: Number
    },
    isHasDownExcel: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    Bus.$on('resetChangeState', () => {
      this.changeState = false
    })
  },
  computed: {
    ...mapGetters({
      uid: 'uid',
      mockSearch: 'mockSearch',
      selfList: 'selfList',
      user: 'user'
    }),
    showGrayTag: function () {
      const tema = this.detail.questionState !== 0
      const temb = !this.changeState && (this.detail.matchingType === 2 || this.detail.matchingType === 3 || this.detail.matchingType === -1)
      const temc = this.changeState && (this.state === 2 || this.state === 3 || this.state === -1)
      return tema || temb || temc
    },
    tagname: function () {
      return (this.detail.tagname && this.detail.tagname.split(',')[0]) || ''
    },
    areaNeed () {
      let tem = '暂无数据'
      if (this.detail && this.detail.areaMin && this.detail.areaMax) {
        tem = this.detail.areaMin + '-' + this.detail.areaMax + '平米'
      }
      return tem
    },
    brandName () {
      let brandName = this.detail.brandName
      if (this.detail.brandEnglishName !== '') {
        brandName += this.detail.brandEnglishName
      }
      return brandName
    },
    brandLogo () {
      return (this.detail && this.detail.brandLogo) || 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'
    },
    extendArea () {
      return this.detail.tuoZhanQuYu && (this.detail.tuoZhanQuYu.indexOf('、') !== -1 ? this.detail.tuoZhanQuYu.split('、') : this.detail.tuoZhanQuYu.split(','))
    },
    matchScore () {
      let matchScore
      if (this.detail.matchScore) {
        matchScore = this.detail.matchScore.toFixed(1)
      } else {
        matchScore = '--'
      }
      return matchScore
    },
    check () {
      return this.detail.check
    }
  },
  methods: {
    selectDownBrand (brandId) {
      const list = JSON.parse(JSON.stringify(this.selfList))
      list.dataList = list.dataList.map((item) => {
        if (item.brandId === brandId) {
          item.check = !item.check
        }
        return item
      })
      this.$store.dispatch('setSelfBrandList', list)
    },
    getScoreClick () {
      // console.log(this.matchType)
      if (this.matchType === 2) {
        const mockHref = this.$router.resolve({
          path: '/MockRoute',
          query: {
            source: 2
          }
        })
        window.open('/' + mockHref.href, '_blank')
      } else {
        this.$emit('showMatch', true)
      }
    },
    updateAttention (id, type) {
      const temType = type === 1 ? 0 : 1
      const params = {
        attent_state: temType,
        brand_project_id: id,
        state: 2,
        uid: this.uid
      }
      this.axios.post(api.updateAttention, params)
        .then((res) => {
          if (res.data.success === 1) {
            // let temCoo = getCookie('changeBrand') === '' ? '' : (getCookie('changeBrand') + ',')
            const params = {
              // brand_id: temCoo + this.mockSearch.brand_id,
              // pageNum: 1,
              // custom_type: '',
              // radio_type: 0,
              loading: true
            }
            this.$store.dispatch('setMockSearch', params)
            this.axios.post(api.projectBrandList, checkParams(this.mockSearch))
              .then((res) => {
                if (res.data.success === 1) {
                  const params = {
                    loading: false
                  }
                  this.$store.dispatch('setMockBrandList', res.data.data)
                  this.$store.dispatch('setMockSearch', params)
                  const tem = temType === 1 ? '关注' : '取消'
                  this.$message({
                    type: 'success',
                    message: tem + '品牌成功！'
                  })
                }
              })
          }
        })
    },
    toRefleshList () {
      const currentTmp = {
        key: 'currentCount',
        value: '0',
        expireDays: 0
      }
      setCookie(currentTmp)
      this.$confirm('您已改变五个品牌的状态，是否重新匹配？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Bus.$emit('reflash')
          const temCoo = getCookie('changeBrand') === '' ? '' : (getCookie('changeBrand') + ',')
          const params = {
            brand_id: temCoo + this.mockSearch.brand_id,
            pageNum: 1,
            area_id: '',
            custom_type: '',
            radio_type: 0,
            loading: true
          }
          this.$store.dispatch('setMockSearch', params)
          this.axios.post(api.projectBrandList, checkParams(this.mockSearch))
            .then((res) => {
              if (res.data.success === 1) {
                this.$message({
                  type: 'success',
                  message: '重新匹配成功！'
                })
                const params = {
                  loading: false
                }
                this.$store.dispatch('setMockBrandList', res.data.data)
                this.$store.dispatch('setMockSearch', params)
              }
              const brandTmp = {
                key: 'changeBrand',
                value: '',
                expireDays: 0
              }
              setCookie(brandTmp)
              const cancelCountTmp = {
                key: 'cancelCount',
                value: '0',
                expireDays: 0
              }
              setCookie(cancelCountTmp)
            })
        })
        .catch(() => {
          const brandTmp = {
            key: 'changeBrand',
            value: '',
            expireDays: 0
          }
          setCookie(brandTmp)
          const cancelCountTmp = {
            key: 'cancelCount',
            value: '0',
            expireDays: 0
          }
          setCookie(cancelCountTmp)
        })
    },
    showState (id) {
      if (this.showFirstMask) {
        return
      }
      this.currentId !== 0 ? this.$emit('update:currentId', 0) : this.$emit('update:currentId', id)
    },
    toDetail (id, questionState, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=1' + '&questionState=' + questionState + '&gbid=' + gbid, '_blank')
    },
    toInviteInfo (id, name, questionState, isTuozhan, gbid) {
      // if (isTuozhan === 2) {
      //   this.$message({
      //     type: 'warning',
      //     message: '当前品牌暂无拓展计划，暂不支持发起委托邀约！'
      //   })
      //   return
      // }
      if (this.user.project === '') {
        this.$message({
          type: 'warning',
          message: '当前账号尚未绑定项目，请绑定项目后发起委托邀约，绑定请联系客服微信号：win18578675071！'
        })
        return
      }
      if (this.showFirstMask) {
        return
      }
      // 开业版
      if (this.user.openingStatus === 1) {
        // let InviteInfohref = this.$router.push({
        //   'path': '/InviteInfo',
        //   'query': {
        //     'id': id,
        //     'name': encodeURIComponent(name)
        //   }
        // })
        // window.open('/' + InviteInfohref.href, '_blank')
        window.open('http://' + window.location.host + '/#/InviteInfo?id=' + id + '&name=' + encodeURIComponent(name), '_blank')
      } else {
        if ((this.changeState && this.state === -1)) {
          this.$message({
            type: 'warning',
            message: '该品牌已标记为不合适，不可使用委托邀约！'
          })
          return
        }
        // if ((this.changeState && this.state === 1)) {
        //   this.$message({
        //     type: 'warning',
        //     message: '该品牌已标记为模拟签约，不可使用委托邀约！'
        //   })
        //   return
        // }
        if ((this.changeState && this.state === 2)) {
          this.$message({
            type: 'warning',
            message: '品牌已标记为洽谈中，不可使用委托邀约！'
          })
          return
        }
        if ((this.changeState && this.state === 3)) {
          this.$message({
            type: 'warning',
            message: '该品牌已标记为已签约，不可使用委托邀约！'
          })
          return
        }
        if (!this.changeState && (this.detail.questionState !== 0 || this.detail.matchingType !== 0)) {
          if (this.detail.matchingType === 3) {
            this.$message({
              type: 'warning',
              message: '该品牌已标记为已签约，不可使用委托邀约！'
            })
            return
          }
          if (this.detail.matchingType === -1) {
            this.$message({
              type: 'warning',
              message: '该品牌已标记为不合适，不可使用委托邀约！'
            })
            return
          }
          // if (this.detail.matchingType === 1) {
          //   this.$message({
          //     type: 'warning',
          //     message: '该品牌已标记为模拟签约，不可使用委托邀约！'
          //   })
          //   return
          // }
          if (this.detail.matchingType === 2) {
            this.$message({
              type: 'warning',
              message: '该品牌已标记为洽谈中，不可使用委托邀约！'
            })
            return
          }
          if (this.detail.questionState === 3) {
            this.$message({
              type: 'warning',
              message: '该品牌已邀约成功！'
            })
            return
          }
          if (this.detail.questionState === 41) {
            this.$message({
              type: 'warning',
              message: '该品牌已标记为不合适，不可使用委托邀约！'
            })
            return
          }
        }
        let winOpen = ''
        const params = {
          uid: this.uid,
          gbid: gbid,
          brand_id: id
        }
        if (questionState === 1 || questionState === 2 || questionState === 3 || questionState === 40 || questionState === 41 || this.user.num === 0) {
        } else {
          winOpen = window.open('', '_blank')
        }
        this.axios.post(api.getUserRightsNum, params)
          .then((res) => {
            if (res.data.code === 0) {
              if (questionState === 1 || questionState === 2 || questionState === 3) {
                this.$message({
                  type: 'warning',
                  message: '您正在邀约该品牌，暂不能再发出邀约'
                })
                return
              } else if (questionState === 40) {
                this.$message({
                  type: 'warning',
                  message: '您已邀约过该品牌'
                })
              } else if (questionState === 41) {
                this.$message({
                  type: 'warning',
                  message: '该品牌已邀约失败，不能再次邀约'
                })
              }
              if (res.data.data <= 0) {
                this.$message({
                  type: 'warning',
                  message: '您的邀约权益量已使用完，不能再进行委托邀约，如需增加邀约权益量，请联系客服微信号：win18578675071',
                  duration: 3000
                })
              }
            }
          })
          .then((res) => {
            if (questionState === 1 || questionState === 2 || questionState === 3 || questionState === 40 || questionState === 41 || this.user.num === 0) {
            } else {
              setTimeout(() => {
                winOpen.location = 'http://' + window.location.host + '/#/InviteInfo?id=' + id + '&name=' + encodeURIComponent(encodeURIComponent(name)) + '&source=1' + '&gbid=' + gbid
              }, 100)
            }
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-item
  position relative
  float left
  width 200px
  height 260px
  margin 10px 16px 10px 0
  padding 9px 7px 9px 8px
  background-color #fff
  box-sizing border-box
  border 2px solid #fff
  &:nth-child(6n)
    margin-right 0
.list-item-self
  height 174px
  .brand-label-list
    bottom 10px
  .select-down-active
    position absolute
    right -1px
    bottom -1px
    width 27px
    height 25px
    background url('~@/assets/images/brandDetail/checked.png') no-repeat
    background-size 100%
    z-index 1111
    cursor pointer
.addpic
  position absolute
  width 21px
  height 24px
  right -3px
  top -10px
  z-index 111
.item-wrap
  position relative
  font-size 14px
  p
    padding-top 5px
    padding-bottom 3px
    font-size 12px !important
    color #333
    overflow hidden
    &:first-of-type
      padding-top 13px
      padding-bottom 0px
      line-height 17px
      height 18px
.item-image
  position relative
  display inline-block
  width 55px
  height 53px
  background no-repeat center center
  background-size 100% auto
  vertical-align bottom
  background-color #fff
  cursor pointer
.item-info
  display inline-block
  width 90px
  margin-left 5px
  color #4c4c4c
  line-height 120%
.item-info-xx
  position absolute
  top 45px
  left 75px
  z-index 11
.item-info-tit
  display inline-block
  position absolute
  top -2px
  left 65px
  width 100px
  height 33px
  font-size 14px
  font-weight 600
  color #333
  cursor pointer
  overflow hidden
  text-overflow ellipsis
  display -webkit-box
  -webkit-line-clamp 2
  -webkit-box-orient vertical
.item-info-tit1
  position absolute
  top 28px
  left 100px
  width 100px
  font-size 14px
  cursor pointer
  word-wrap break-word
.item-info-talk
  position relative
  display inline-block
  color #333
  background-color #FAFAFA
  cursor pointer
  width 60px
  height 22px
  text-align center
  line-height 22px
  font-size 12px
  border-radius 4px
  // box-shadow 0px 2px 2px #EEEEEE
  border 1px solid rgba(238, 238, 238, 1)
.icon-tel-while
  padding 3px
  background-color #118e2b
  vertical-align bottom
.item-rate
  display inline-block
  // vertical-align middle
.item-btn1
  position absolute
  bottom 11px
  width 179px
  height 30px
  line-height 30px
  font-size 12px
  text-align center
  border-top 1px solid #cdcdcd
  border-right 1px solid #949494
  border-bottom 1px solid #7c7c7c
  border-left 1px solid #949494
  color #333
  cursor pointer
.item-btn
  position absolute
  bottom 50px
  width 181px
  height 30px
  line-height 29px
  font-size 12px
  text-align center
  border-top 1px solid #cdcdcd
  border-right 1px solid #949494
  border-bottom 1px solid #7c7c7c
  border-left 1px solid #949494
  color #333
  cursor pointer
.brand-label-list
  margin-top 10px
  position absolute
  bottom 100px
  left 10px
  span
    display inline-block
    font-size 12px
    color #B8A794
    padding 4px 6px
    border-radius 6px
    background-color #FFF8F0
.item-btn-select
  posotion relative
  color #333
  box-sizing border-box
  background-color #fff
  text-align center
  z-index 22
  &:after
    position absolute
    right 27px
    top 55%
    transform translateY(-50%)
    transition all 0.3s ease
    font-family 'iconfont'
    font-size 12px
    font-style normal
    -webkit-font-smoothing antialiased
    -webkit-text-stroke-width 0.2px
    display inline-block
    content '\e637'
    color #666666
.item-btn-select-active
  &:after
    transform translateZ(0)
    transform translateY(-50%) rotate(180deg)
.item-btn-mock
  padding-left 0
  background-color #36665a
  border-color #36665a
  color #fff
  text-align center
  &:after
    color #fff
.item-btn-signed
  padding-left 0
  background-color #c35d3e
  border-color #c35d3e
  color #fff
  text-align center
  &:after
    color #fff
.item-btn-out
  padding-left 0
  background-color #808080
  border-color #808080
  color #fff
  text-align center
  &:after
    color #fff
.item-btn-signing
  padding-left 0
  background-color #2e548c
  border-color #2e548c
  color #fff
  text-align center
  &:after
    color #fff
.item-state
  position absolute
  left -1px
  top 29px
  box-shadow 0 4px 10px #888888
  z-index 11
  li
    width 181px
    margin 0
    padding 0
    height 32px
    line-height 32px
    background-color #d9d9d9
    text-align center
    color #595959
    cursor pointer
    &:hover
      background-color #525968
      color #fff
  .item-state-active
    background-color #525968
    color #fff
.brand-area
  float left
  margin-right -12px
.brand-area-right
  width 185px
  height 66px
  margin-top 2px
  margin-left 11px
  overflow hidden
  text-overflow ellipsis
  display -webkit-box
  -webkit-line-clamp 4
  -webkit-box-orient vertical
    display inline-block
    margin-left 2px
  span
    font-size 12px
.info-talk-blue
  background-color #3585ff
.item-collect
  position absolute
  top -11px
  left -14px
  display inline-block
  width 44px
  height 22px
  line-height 22px
  background-color #ffc952
  color #4c4c4c
  font-size 12px
  padding-left 8px
  z-index 2
  &:after
    position absolute
    content ''
    right -8px
    width 0
    height 0
    border-top 11px solid #ffc952
    border-right 8px solid transparent
  &:before
    position absolute
    content ''
    right -8px
    bottom 0px
    width 0
    height 0
    border-bottom 11px solid #ffc952
    border-right 8px solid transparent
.invite-tag-gray
  color #999
.list-item:hover
  box-shadow 0px 1px 6px 0px #bbb
  border 2px solid rgba(217, 217, 217, 1)
  .unchecked-img
    position absolute
    right -1px
    bottom -1px
    width 27px
    height 25px
    background url('../../assets/images/brandDetail/check.png') no-repeat
    background-size 100%
    z-index 111
    cursor pointer
.matchScore-no
  position absolute
  right 0
  top 0
  width 40px
  height 18px
  line-height 18px
  font-size 12px
  text-align center
  background rgba(0, 0, 0, 0.6)
  color #fff
.matchScore-yes
  position absolute
  right 0
  top 0
  width 40px
  height 18px
  line-height 18px
  font-size 12px
  text-align center
  background rgba(255, 161, 52, 0.8)
  color #fff
  z-index 1
.noScore
  text-decoration underline
  cursor pointer
.update
  position absolute
  right 0
  top 0
  width 60px
  height 18px
  line-height 18px
  font-size 12px
  text-align center
  background rgba(255, 161, 52, 0.8)
  color #fff
  z-index 1
.select-down-active-li
  border 2px solid #ffa134
.select-down-active-li:hover
  border 2px solid #ffa134
  .unchecked-img
    position absolute
    right -1px
    bottom -1px
    width 27px
    height 25px
    background url('../../assets/images/brandDetail/checked.png') no-repeat
    background-size 100%
    z-index 11111
    cursor pointer
.star-tag
  position absolute
  left 3px
  top 3px
  width 20px
  height 27px
  background url('../../assets/images/star-label.png') no-repeat
  background-size 100%
  z-index 11
</style>
